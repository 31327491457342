<template>
  <FormStepWrapper
    :stepNumber="step.step"
    :activeStep="activeStep"
  >
    <h2 class="myrecipe-formstep-title center helvetica-bold h1 mx-auto max-width-3 mb1 px3 border-box">{{ $t('rsf_formStep_heading_04') }}</h2>
    <p class="helvetica-roman color-13 h2 center max-width-3 px3 mx-auto border-box mb3">{{ $t('rsf_formStep_subtitle_04') }}</p>
    <div class="flex gap2 justify-center col-12 px2 border-box">
      <Card_WaterVolume
        :setActiveStep="setActiveStep"
        :setFormData="setFormData"
      />
    </div>
  </FormStepWrapper>
</template>

<script>

import FormStepWrapper from './FormStepWrapper.vue'
import Card_WaterVolume from './Card_WaterVolume.vue'

export default {
  name: 'FormStep_WaterVolume',
  props: {
    siteLanguage: String,
    step: Object,
    activeStep: Number,
    setActiveStep: Function,
    setFormData: Function,
  },
  components: {
    Card_WaterVolume,
    FormStepWrapper,
}
}
</script>
<style>
</style>