<template>
  <div class="border-box myrecipe_result_wrapper">
    <div class="myrecipe-result">
      <div class="lg-pb5 pb4 bg-color-11 px2 border-box">
        <h1 class=" center h0 mb3 open-sans-bold">{{ $t('myrecipe_result_heading') }}</h1>
        <RecipeCard :formData="formData" :recipeData="activeTab == 1 && Object.keys(recipeData).length > 0 ? recipeData : recipeDataWithoutRefugium" />
      </div>
      <div class="col-12 bg-color-white lg-pt5 pt4 lg-pb5 pb4 px2 border-box">
        <div class="max-width-3 mx-auto">
          <h2 class="h0 center helvetica-bold mb3 max-width-2 mx-auto">{{ $t('myrecipe_how_to_keep_parameters') }}</h2>

        <Recipe_Tab_Carousel
          :slide1Heading="$t('rsf_label_without_refugium')"
          :slide2Heading="$t('rsf_label_with_refugium')"
          :activeTab="activeTab"
          :updateActiveTab="updateActiveTab"
        >
          <!-- <div slot="additional-top-content" class="flex gap2 items-start mb5 refugium-info-box">
            <img src="https://redseafish.com/wp-content/uploads/2023/09/icon_bulb.svg" />
            <p class="open-sans-regular h5 color-14">
              {{ $t('myrecipe_refugium_info_text') }}
            </p>
          </div> -->

          <!-- without refugium -->
          <div slot="slide-1">
            <div v-if="Object.keys(recipeDataWithoutRefugium).length == 0" class="mx-auto py4">
              <img class="block center mx-auto" src="https://static.redseafish.com/wp-content/uploads/2023/09/07130304/spinner2.gif"/>
            </div>
            <MyRecipe_Result_Slide
              v-if="Object.keys(recipeDataWithoutRefugium).length > 0"
              :recipeData="recipeDataWithoutRefugium"
              :getModalRef="() => $refs['supplement-details-modal']"
              :fetchProductDescription="fetchProductDescription"
              :slideInnerId="'recipe-slide-1'"
              :isWithoutRefugium="true"
              :siteLanguage="siteLanguage"
            />
          </div>

          <!-- with refugium -->
          <div slot="slide-2">
            <div v-if="Object.keys(recipeData).length == 0" class="mx-auto py4">
              <img class="block center mx-auto" src="https://static.redseafish.com/wp-content/uploads/2023/09/07130304/spinner2.gif"/>
            </div>
            <MyRecipe_Result_Slide
              ref="recipeSlide2"
              v-if="Object.keys(recipeData).length > 0"
              :recipeData="recipeData"
              :getModalRef="() => $refs['supplement-details-modal']"
              :fetchProductDescription="fetchProductDescription"
              :slideInnerId="'recipe-slide-2'"
              :isWithoutRefugium="false"
              :siteLanguage="siteLanguage"
            />
          </div>
        </Recipe_Tab_Carousel>


        <div class="overflow-hidden col-12 mt4">
          <div class="sm-flex sm-flex-row flex flex-column-reverse justify-center gap3 mx-auto items-center">
            <router-link to="/" class="open-sans-semibold color-1 h5 rsf-btn rsf-btn-tertiary">{{ $t('myrecipe_start_over') }}</router-link>
            <div @click="$refs['email-modal'].open(); resetEmailForm()" class="open-sans-semibold color-white h5 rsf-btn rsf-btn-primary color-white bg-color-1">{{ $t('myrecipe_send_to_email') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MyRecipe_Modal :ref="'email-modal'">
    <Form_SendEmail :siteLanguage="siteLanguage" :recipeData="activeTab == 2 && Object.keys(recipeData).length > 0 ? recipeData : recipeDataWithoutRefugium"  />
  </MyRecipe_Modal>
  <MyRecipe_Modal ref="supplement-details-modal">
    <ProductDescription :siteLanguage="siteLanguage"/>
  </MyRecipe_Modal>
  <!-- <div class="rsf-btn rsf-btn-primary color-white bg-color-1" @click="$refs['modal-1'].open()">Open mdoal</div> -->
  </div>
</template>

<script>

import RecipeCard from './RecipeCard.vue';
import ParameterSection from './ParameterSection.vue';
import SupplementItem from './SupplementItem.vue';
import MyRecipe_Modal from './MyRecipe_Modal.vue';
import Tab_Carousel from './Tab_Carousel.vue';
import Form_SendEmail from './Form_SendEmail.vue';
import FeatureCard from './FeatureCard.vue';
import ProductDescription from './ProductDescription.vue';
import MyRecipe_Result_Slide from './MyRecipe_Result_Slide.vue';
import Recipe_Tab_Carousel from './Recipe_Tab_Carousel.vue';
import { EventBus } from '@/event-bus';
export default {
  name: 'MyRecipe_Result',
  props: {
    formData: Object,
    recipeData: Object,
    recipeDataWithoutRefugium: Object,
    siteLanguage: String,
    updateCountry: Function
  },
  components: {
    RecipeCard,
    ParameterSection,
    SupplementItem,
    MyRecipe_Modal,
    Tab_Carousel,
    Form_SendEmail,
    FeatureCard,
    ProductDescription,
    MyRecipe_Result_Slide,
    Recipe_Tab_Carousel
},
  data() {
    return {
      activeTab: 1,
    }
  },
  created(){
    if(Object.keys(this.recipeDataWithoutRefugium).length == 0 && Object.keys(this.recipeData).length == 0){
      this.$router.push({path: '/' + this.siteLanguage})
    }
  },
  mounted(){
    if(Object.keys(this.recipeDataWithoutRefugium).length > 0){
      this.activeTab = 1;
    }else{
      this.activeTab = 2;
    }
  },
  methods: {
    openModal(){
      EventBus.$emit('open_modal')
    },
    resetEmailForm(){
      EventBus.$emit('reset_email_form');
    },
    fetchProductDescription(id){
      EventBus.$emit('fetch_product_description', id);
    },
    updateActiveTab(tab){
      this.activeTab = tab;
      if(this.activeTab == 1 && Object.keys(this.recipeDataWithoutRefugium).length == 0){
        console.log('pull recipe data');
        EventBus.$emit('fetch_recipe_data_without_refugium');
      }else if(this.activeTab == 2 && Object.keys(this.recipeData).length == 0){
        console.log('pull recipe data without refugium');
        EventBus.$emit('fetch_recipe_data');
      }
    }
  }
}
</script>
<style scoped>
@keyframes fade-in {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

.myrecipe_result_wrapper {
  min-height: 100vh;
}

.myrecipe-result {
  animation-name: fade-in;
  animation-duration: 2s;
}

.myrecipe_result_wrapper {
  /* min-height: 100vh; */
  padding: 3rem 0;
  padding-bottom: 0;
}

@media(max-width: 1024px){
  .myrecipe_result_wrapper {
    padding-top: calc(3rem + var(--fullMenuHeight));
    padding-bottom: 0;
  }
}
</style>