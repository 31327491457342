<template>
  <FormStepWrapper
    :stepNumber="step.step"
    :activeStep="activeStep"
  >
    <h2 class="myrecipe-formstep-title center helvetica-bold h1 mx-auto max-width-2 mb1 px3 border-box">{{ $t('rsf_formStep_heading_03') }}</h2>
    <p class="helvetica-roman color-14 h5 center max-width-3 px3 mx-auto border-box mb3">{{ $t('rsf_formStep_subtitle_03') }}</p>
    <div class="flex gap2 justify-center col-12 px2 border-box">
      <CountrySelectCard
        :setActiveStep="setActiveStep"
        :setFormData="setFormData"
        :siteLanguage="siteLanguage"
        :updateCountry="updateCountry"
        :selectedCountry="selectedCountry"
      />
    </div>
  </FormStepWrapper>
</template>

<script>

import FormStepWrapper from './FormStepWrapper.vue'
import CountrySelectCard from './CountrySelectCard.vue'
import MobileCarousel from './MobileCarousel.vue'

export default {
  name: 'FormStep_Country',
  props: {
    siteLanguage: String,
    updateCountry: Function,
    step: Object,
    activeStep: Number,
    setActiveStep: Function,
    setFormData: Function,
    selectedCountry: Object,
    yourCountry: Object
  },
  components: {
    CountrySelectCard,
    FormStepWrapper,
    MobileCarousel
}
}
</script>
<style>
</style>