import { render, staticRenderFns } from "./Recipe_Tab_Carousel.vue?vue&type=template&id=47935946&scoped=true"
import script from "./Recipe_Tab_Carousel.vue?vue&type=script&lang=js"
export * from "./Recipe_Tab_Carousel.vue?vue&type=script&lang=js"
import style0 from "./Recipe_Tab_Carousel.vue?vue&type=style&index=0&id=47935946&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47935946",
  null
  
)

export default component.exports