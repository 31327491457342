import { render, staticRenderFns } from "./Card_Refugium.vue?vue&type=template&id=776ed0a0&scoped=true"
import script from "./Card_Refugium.vue?vue&type=script&lang=js"
export * from "./Card_Refugium.vue?vue&type=script&lang=js"
import style0 from "./Card_Refugium.vue?vue&type=style&index=0&id=776ed0a0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "776ed0a0",
  null
  
)

export default component.exports