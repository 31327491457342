<template>
  <div class="myrecipe-mobile-carousel-wrapper">

    <div v-if="!showTabs" class="flex items-center gap1 justify-center mb2">
      <div @click="scrollToActiveSlide(index)" v-for="(card, index) in step.cards" :key="index" :class="`myrecipe-mobile-carousel-tab-item ${activeSlide == index ? 'active' : ''}`"></div>
    </div>
    <div ref="myrecipeMobileCarousel" class="myrecipe-mobile-carousel flex border-box px2 gap2">
        <Card_TankType
          v-for="(card, index) in step.cards"
          :key="index"
          :card="card"
          :setActiveStep="setActiveStep"
          :setFormData="setFormData"
        />
    </div>
  </div>
</template>

<script>

import Card_TankType from './Card_TankType.vue';

export default {
  name: 'MobileCarousel',
  components: {
    Card_TankType
  },
  data(){
    return{
      activeSlide: 0,
      screenWidth: 0,
      railHeight: 0,
      percentageScrolled: 0,
      screenPercentageMoved: 0,
      showTabs: false,
      cardPositions: [],
      blockHandleScroll: false
    }
  },
  props: {
    step: Object,
    setActiveStep: Function,
    setFormData: Function
  },
  mounted(){
    this.getScreenWidth();
    this.$refs.myrecipeMobileCarousel.addEventListener('scroll', (e) => {
      this.handleScroll();
    })
    window.addEventListener('resize', () => {
      this.getScreenWidth();
      this.getCardPositions();
    })
  },
  methods: {
    getScreenWidth(){
      this.screenWidth = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    },
    getCardPositions(){
      this.cardPositions = [];
      const cardWidth = 262;
      const gap = 16;
      for(let i = 0; i < this.step.cards.length; i ++){
        this.cardPositions.push((i) * (cardWidth + gap) + gap + (cardWidth)/2)
      }
    },
    scrollToActiveSlide(activeSlide){
      this.blockHandleScroll = true;
      const carousel = this.$refs['myrecipeMobileCarousel'];
      const screenWidth = this.screenWidth;
      const centerOfScreen = screenWidth/2

      const scrollLeft =  this.cardPositions[activeSlide] - centerOfScreen

      carousel.scrollTo({
        top: 0,
        left: scrollLeft,
        behavior: 'smooth'
      });

      this.activeSlide = activeSlide
      setTimeout(() => {
        this.blockHandleScroll = false;
      }, 400)

    },
    handleScroll(){
      if(this.blockHandleScroll){return;}
      const carousel = this.$refs['myrecipeMobileCarousel'];
      for(let i = 0; i < this.cardPositions.length; i++){
        if(carousel.scrollLeft - 16 + this.screenWidth/2 >= this.cardPositions[i] - 20 && carousel.scrollLeft - 16 +this.screenWidth/2 <= this.cardPositions[i] + 20){
          this.activeSlide = i;
        }
      }
      if(carousel.scrollLeft <= 20){
        this.activeSlide = 0;
      }
      if(carousel.scrollLeft >= carousel.scrollWidth - carousel.clientWidth - 20){
        this.activeSlide = this.step.cards.length - 1;
      }
    },
  },
  watch: {
      'step.cards'(){
        this.getCardPositions();
      }
    }
}
</script>
<style scoped>
.myrecipe-mobile-carousel-wrapper{
  display:none;
}
@media(max-width: 1120px){
  .myrecipe-mobile-carousel-wrapper{
    display: block;
  }
}
.myrecipe-mobile-carousel{
  width: calc(100vw - var(--scrollbar-width));
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; 
  position: relative;
  scroll-snap-type: x mandatory;
}
.myrecipe-mobile-carousel > div{
  scroll-snap-align: center;
}
.myrecipe-mobile-carousel::-webkit-scrollbar{
  display: none;
}
.myrecipe-mobile-carousel-tab-item{
  width: 24px;
  height: 3px;
  background-color: var(--rsf-2022-color-12);
  padding: .5rem 0; 
  background-clip: content-box;
  cursor: pointer;
}
.myrecipe-mobile-carousel-tab-item.active{
  height: 5px;
  background-color: var(--rsf-2022-color-1);
  border-radius: 99px;
  padding: 0;
  pointer-events: none;
}
.myrecipe-mobile-carousel-rail{
  position: absolute;
  top: 0; 
  left: 0; 
}
.myrecipe-mini-scrollbar{
  width: 120px;
  margin: 0 auto; 
  height: 5px;
  background-color: var(--rsf-2022-color-12);
  margin-bottom: 2rem
}

.myrecipe-mini-scrollbar-view{
  background-color: var(--rsf-2022-color-1);
  height: 5px;
}
</style>