<template>
  <FormStepWrapper
    :stepNumber="step.step"
    :activeStep="activeStep"
  >
    <h2 class="myrecipe-formstep-title center helvetica-bold h1 mx-auto max-width-2 mb1 px3 border-box">{{ $t('rsf_formStep_heading_01') }}</h2>
    <p class="helvetica-roman color-14 h5 center max-width-3 px3 mx-auto border-box mb3">{{ $t('rsf_formStep_subtitle_01') }}</p>
    <div class="flex gap2 justify-center col-12 myrecipe-static-cards px2 border-box">  
      <Card_TankType
        v-for="(card, index) in step.cards"
        :key="index"
        :card="card"
        :setActiveStep="setActiveStep"
        :setFormData="setFormData"

      />
    </div>
    <MobileCarousel
      :step="step"
      :setActiveStep="setActiveStep"
      :setFormData="setFormData"
    />
  </FormStepWrapper>
</template>

<script>

import FormStepWrapper from './FormStepWrapper.vue'
import Card_TankType from './Card_TankType.vue';
import MobileCarousel from './MobileCarousel.vue'

export default {
  name: 'FormStep_TankType',
  props: {
    step: Object,
    activeStep: Number,
    setActiveStep: Function,
    setFormData: Function
  },
  components: {
    FormStepWrapper,
    MobileCarousel,
    Card_TankType
}
}
</script>
<style>
  @media(max-width: 1120px){
    .myrecipe-static-cards.flex{
      display: none;
    }
  }
</style>