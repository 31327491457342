<template>
  <div class="rsf-select-wrapper relative">
    <svg :style="`transform: rotate(180deg) translateY(50%)`" width="24" height="24" viewBox="0 0 24 24"
          fill="none">
          <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
  <select @change="updateCountry(displayedCountry)" v-model="displayedCountry" class="rsf-select pointer h5" :style="`${Object.keys(displayedCountry) == 0 ? 'color: var(--rsf-2022-color-13)' : ''}`">
    <option :value="{}" disabled selected hidden>{{$t('rsf_country_select_prompt')}}</option>
    <option v-for="country in countries" :key="country.id" :value="country">{{country.countryname}}</option>
    <!-- <option :value="{'countryname': $t('myrecipe_other_country'), 'isocode2': 'XX' }">{{ $t('myrecipe_other_country') }}</option> -->
  </select>
</div>
</template>

<script>
import axios from 'axios'
// import { EventBus } from '@/event-bus'

export default {
  name: 'CountrySelect',
  props: {
    siteLanguage: String,
    updateCountry: Function,
  },
  data() {
    return {
      countries: [],
      displayedCountry: {}
    }
  },
  mounted(){
    let that = this
    axios
      .post(process.env.VUE_APP_RSF_API + "/public-api/list-countries/myrecipe/json", { 'lang': this.siteLanguage })
      .then(function (response) {
        that.countries = response.data.data;
        that.preSelectCountry();
        let otherCountry = {};
        for (let i = 0; i < that.countries.length; i++) {

          if (that.countries[i].isocode2 == 'XX') {
            otherCountry = that.countries[i];
            that.countries.splice(i, 1);
            
          }
          // if (that.countries[i].isocode2 == 'JA') {
          //   that.countries.splice(i, 1);
          // }
          // if (that.countries[i].isocode2 == 'CN' && this.siteLanguage != 'cn') {
          //   that.countries.splice(i, 1);
          // }
        }
        //put other country at the bottom
        that.countries.push(otherCountry)
        // that.selectedCountry = response.data.data[0]
        that.fetchData();

      })
      .catch(function (error) {

      });
  },
  methods: {
    preSelectCountry() {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].preselection_by_ip) {
          console.log('prselected by IP!')
          console.log(this.countries[i].isocode2)
          this.displayedCountry = this.countries[i];
          this.updateCountry(this.countries[i]);
          if(this.countries[i].isocode2 == 'US'){
            this.measurement = 'imperial'
          }else{
            this.measurement = 'metric'
          }
          return;
        }
      }
        this.findCountry('XX') // fallback Other country
    },
    findCountry(isocode) {
      for (let a = 0; a < this.countries.length; a++) {
        if (this.countries[a].isocode2 == isocode) {
          this.displayedCountry = this.countries[a]
          this.updateCountry(this.countries[a]);
        }
      }
    },
  }
}
</script>

<style scoped>
  .rsf-country-select{
    padding: 0.9535rem 1.75rem;
    background-color: var(--rsf-2022-color-white);
    border-radius: 999px;
    width: 100%;
    border: 1px solid var(--rsf-2022-color-12);
    font-size: var(--h5) !important;
  }

  .rsf-country-select-wrapper{
    max-width: 500px;
    width: 100%;
  }

  .rsf-country-select-wrapper > svg{
    position: absolute; 
    top: 50%;
    right: 1rem;
  }

    @media(max-width: 420px){
    .rsf-country-select{
      padding: 0.9535rem 1.5rem;
      text-overflow: ellipsis;
      padding-right: 2.25rem;
    }
  }
</style>
