import App from './App.vue'
import MyRecipe_Wizard from './components/MyRecipe_Wizard'
import MyRecipe_Result from './components/MyRecipe_Result'

export default [
    { 
        path: '/:lang', 
        component: MyRecipe_Wizard,

    },
    { 
        path: '/:lang/result', 
        component: MyRecipe_Result,

    },
    {
      path: "*",
      redirect: "/en"
    }
]