<template>
  <div class="pt3 pb3 px2 md-px3">
    <div v-show="loading">
      <img class="block mx-auto py4" src="https://static.redseafish.com/wp-content/uploads/2023/09/07130304/spinner2.gif"/>
    </div>
    <div v-if="!loading">
      <div class="myrecipe-chemicals-label bg-color-1 color-white open-sans-bold h6 mb1" v-if="chemicals != ''">{{chemicals}}</div>
      <h3 class="color-black h1 open-sans-bold mb1" v-if="name != ''">{{name}}</h3>
      <p class="helvetica-regular color-black h4 mb3" v-if="subheadline != ''">{{ subheadline }}</p>
      <img class="contain col-12 mb3" style="height: 330px;" v-if="img != ''" :src="img"/>
      <div class="color-black open-sans-regular h5" v-if="description != ''" v-html="description.replace('NO3:PO4-X', 'NO<sub>3</sub>;PO<sub>4</sub>-X')"></div>
      <a v-if="link != ''" target="_blank" :href="link" class="rsf-btn rsf-btn-tertiary color-1 open-sans-semibold h5 my3">{{$t('myrecipe_explore')}}</a>
      <div class="h1px col-12 bg-color-12 mb1"></div>
      <p v-if="sizes != ''" class="open-sans-semibold h6 color-14">{{sizes}}</p>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus';
import axios from 'axios';
export default {
  name: 'ProductDescription',
  props: {
    siteLanguage: String
  },
  data (){
    return {
      chemicals: '',
      name: '',
      subheadline: '',
      img: '',
      description: '',
      link: '',
      sizes: '',
      RNumber: '',
      loading: true,

    }
  },
  created(){
    EventBus.$on('fetch_product_description', (id) => {
      if(this.RNumber != id){
        this.loading = true; 
        this.resetData();
      }
      this.fetchProductDescription(id);
    })
  },
  methods: {
    fetchProductDescription(id){
      let that = this;
      console.log('fetching product description now... ' + id)
      axios
        .get(process.env.VUE_APP_RSF_API + `/public-api/products/recipe-product-data-by-rumbers/${id}/${this.siteLanguage}/json`)
        .then((response) => {
          console.log('fetched successfully');
          console.log(response);
          let r = response.data.productData;
          this.chemicals = r.Chemicals;
          this. name = r.Model;
          this.subheadline = r.ModelSubHeadline;
          this.img = r.thumbnailImage;
          this.description = r.product_description;
          this.link = r.ProductsURL;
          this.sizes = r.SizeMetric;
          this.RNumber = r.RNumber;

          if(process.env.VUE_APP_IS_LIVE == 'true')
          if(typeof dfn_user_region != undefined && dfn_user_region == 'US' && r.ModelSubHeadline_Imperial != ''){
            this.subheadline = r.ModelSubHeadline_Imperial;
            this.sizes = r.SizeImperial;
          }

          this.loading = false; 
        })
        .catch((e) => {
          console.log(e);
        }) 
    },
    resetData(){
      console.log('reset modal data')
      this.chemicals = '';
      this.name = '';
      this.subheadline = '';
      this.img = '';
      this.description = '';
      this.link = '';
      this.sizes = '';
      this.RNumber = '';
    }
  }
}
</script>
<style scoped>
  .myrecipe-chemicals-label{
    padding: 5px 8px;
    border-radius: 2px;
    width: fit-content;
  }

  div.open-sans-regular p{
    font-family: 'Open Sans Regular';
  }
</style>