<template>
  <div class="myrecipe-supplement-item flex items-center py1">
    <div class="relative">
      <img :src="product.images.thumbnailImage" class="thumbnail pointer" @click="handleClick()" />
      <svg class="more-info-icon" width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9" fill="#18E098" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M1.52941 10C1.52941 5.32182 5.32182 1.52941 10 1.52941C14.6782 1.52941 18.4706 5.32182 18.4706 10C18.4706 14.6782 14.6782 18.4706 10 18.4706C5.32182 18.4706 1.52941 14.6782 1.52941 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM11.25 5C11.25 5.69036 10.6904 6.25 10 6.25C9.30964 6.25 8.75 5.69036 8.75 5C8.75 4.30964 9.30964 3.75 10 3.75C10.6904 3.75 11.25 4.30964 11.25 5ZM11.2246 8.49808C11.108 7.92848 10.6041 7.5 10 7.5H8.75C8.05964 7.5 7.5 8.05964 7.5 8.75C7.5 9.44036 8.05964 10 8.75 10V15C8.75 15.6904 9.30964 16.25 10 16.25C10.6904 16.25 11.25 15.6904 11.25 15V8.75C11.25 8.66371 11.2413 8.57945 11.2246 8.49808Z"
          fill="white" />
      </svg>
    </div>
    <div class="item-divider"></div>
    <div class=" px1 sm-px3 col-12">
      <div class="lg-flex items-baseline">
        <p class="open-sans-bold color-black lg-h4 h5">{{ product.Model }}<span class="mx1 hide lg-show"
            v-if="product.RSNAMEUsuallyGoesUnderModel != ''">|</span></p>
        <p class="open-sans-regular h5 color-black mb1 lg-mb0">{{ product.RSNAMEUsuallyGoesUnderModel }}</p>
      </div>
      <p class="open-sans-regular color-14 h6">{{ product.chemicals }}</p>

    </div>
    <div class="item-divider"></div>
    <div v-if="!showIcon" class="px1 mx-auto no-shrink border-box frequency-box">
      <p class="open-sans-bold color-black h5 center nowrap">{{ quantity + ' ' +
        product.recipeSuggestions.units }}</p>
      <p
        :class="`open-sans-regular h5 center ${product.recipeSuggestions.frequency == 'weekly' || product.recipeSuggestions.frequency == 'Weekly' ? 'color-3' : 'color-black'}`">
        {{ frequency }}</p>
    </div>
    <div v-if="showIcon" class="px1 sm-px2 no-shrink border-box frequency-box">
      <svg v-if="product.recipeSuggestions.frequency == 'weekly' || product.recipeSuggestions.frequency == 'Weekly'" class="mx-auto block" width="20" height="23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.988 10.83l.95 3.51h.02l.86-3.51h1.38l.88 3.5h.02l.95-3.5h1.46L12.878 16h-1.46l-.92-3.47h-.02L9.598 16h-1.47l-1.64-5.17h1.5z"
          fill="#2BA0E1" />
        <path d="M19 13a9 9 0 11-9-9" stroke="#2BA0E1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.576 3.576a.6.6 0 010 .848l-2.552 2.552A.6.6 0 019 6.55v-5.1a.6.6 0 011.024-.425l2.552 2.552z"
          fill="#2BA0E1" />
      </svg>
      <svg v-if="product.recipeSuggestions.frequency == 'monthly' || product.recipeSuggestions.frequency == 'Monthly'" class="mx-auto block" width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.98875 10.689C9.74208 10.689 10.2454 10.9757 10.4988 11.549C10.8921 10.9757 11.4154 10.689 12.0688 10.689C12.7154 10.689 13.1888 10.8524 13.4888 11.179C13.7954 11.5057 13.9488 11.9557 13.9488 12.529V15.999H12.5288V13.009C12.5288 12.5557 12.4588 12.2424 12.3188 12.069C12.1854 11.8957 11.9654 11.809 11.6588 11.809C11.0254 11.809 10.7088 12.2424 10.7088 13.109V15.999H9.28875V12.949C9.28875 12.509 9.21875 12.209 9.07875 12.049C8.93875 11.889 8.72208 11.809 8.42875 11.809C8.15542 11.809 7.92542 11.919 7.73875 12.139C7.55875 12.3524 7.46875 12.6357 7.46875 12.989V15.999H6.04875V10.829H7.38875V11.529H7.40875C7.80208 10.969 8.32875 10.689 8.98875 10.689Z" fill="#972EFF"/>
        <path d="M19 12.999C19 14.7791 18.4722 16.5191 17.4832 17.9992C16.4943 19.4792 15.0887 20.6328 13.4442 21.3139C11.7996 21.9951 9.99002 22.1734 8.24419 21.8261C6.49836 21.4788 4.89471 20.6217 3.63604 19.363C2.37737 18.1043 1.5202 16.5007 1.17293 14.7548C0.825665 13.009 1.0039 11.1994 1.68508 9.55487C2.36627 7.91034 3.51983 6.50473 4.99987 5.5158C6.47991 4.52686 8.21997 3.99902 10 3.99902" stroke="#972EFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.5757 3.57574C12.8101 3.81005 12.8101 4.18995 12.5757 4.42426L10.0243 6.97574C9.64628 7.35372 9 7.08601 9 6.55147L9 1.44853C9 0.913985 9.64629 0.646285 10.0243 1.02426L12.5757 3.57574Z" fill="#972EFF"/>
      </svg>
      <svg v-if="product.recipeSuggestions.frequency == 'biweekly' || product.recipeSuggestions.frequency == 'BiWeekly'" class="mx-auto block" width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.54625 10.0242C7.16425 10.0242 7.67725 10.2102 8.08525 10.5822C8.49925 10.9482 8.70625 11.4192 8.70625 11.9952C8.70625 12.2592 8.66725 12.5022 8.58925 12.7242C8.51125 12.9402 8.37625 13.1562 8.18425 13.3722C7.99825 13.5882 7.67125 13.8492 7.20325 14.1552C6.50125 14.5932 6.02125 14.9922 5.76325 15.3522H8.74225V16.4502H4.11625C4.11625 15.9042 4.24525 15.4332 4.50325 15.0372C4.76725 14.6352 5.25625 14.1972 5.97025 13.7232C6.54025 13.3452 6.90025 13.0812 7.05025 12.9312C7.20025 12.7812 7.29925 12.6402 7.34725 12.5082C7.40125 12.3762 7.42825 12.2382 7.42825 12.0942C7.42825 11.8062 7.34425 11.5722 7.17625 11.3922C7.00825 11.2122 6.77725 11.1222 6.48325 11.1222C6.16525 11.1222 5.91925 11.2512 5.74525 11.5092C5.57725 11.7672 5.49025 12.1212 5.48425 12.5712H4.26025V12.4542C4.26025 11.7162 4.46725 11.1282 4.88125 10.6902C5.29525 10.2462 5.85025 10.0242 6.54625 10.0242ZM10.4882 11.2802L11.4382 14.7902H11.4582L12.3182 11.2802H13.6982L14.5782 14.7802H14.5982L15.5482 11.2802H17.0082L15.3782 16.4502H13.9182L12.9982 12.9802H12.9782L12.0982 16.4502H10.6282L8.98822 11.2802H10.4882Z" fill="#6037FF"/>
        <path d="M19.5 13.4502C19.5 15.2302 18.9722 16.9703 17.9832 18.4503C16.9943 19.9304 15.5887 21.0839 13.9442 21.7651C12.2996 22.4463 10.49 22.6245 8.74419 22.2773C6.99836 21.93 5.39471 21.0728 4.13604 19.8142C2.87737 18.5555 2.0202 16.9518 1.67293 15.206C1.32567 13.4602 1.5039 11.6506 2.18508 10.006C2.86627 8.36151 4.01983 6.9559 5.49987 5.96697C6.97991 4.97804 8.71997 4.4502 10.5 4.4502" stroke="#6037FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.0757 4.02593C13.3101 4.26025 13.3101 4.64014 13.0757 4.87446L10.5243 7.42593C10.1463 7.80391 9.5 7.53621 9.5 7.00167L9.5 1.89872C9.5 1.36418 10.1463 1.09648 10.5243 1.47446L13.0757 4.02593Z" fill="#6037FF"/>
      </svg>
      <p class="open-sans-regular h5 center" :style="`${product.recipeSuggestions.frequency == 'weekly' || product.recipeSuggestions.frequency == 'Weekly' ? 'color: #2BA0E1' :  product.recipeSuggestions.frequency == 'biweekly' || product.recipeSuggestions.frequency == 'BiWeekly' ? 'color: #6037FF' : product.recipeSuggestions.frequency == 'monthly' || product.recipeSuggestions.frequency == 'Monthly' ? 'color: #972EFF' : 'color: #2BA0E1'}`">{{ frequency }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SupplementItem",
  props: {
    product: Object,
    handleClick: Function,
    showIcon: Boolean
  },
  computed: {
    frequency() {
      switch (this.product.recipeSuggestions.frequency) {
        case 'daily':
          return this.$t('myrecipe_daily');
          break;
        case 'Daily':
          return this.$t('myrecipe_daily');
          break;
        case 'weekly':
          if(this.showIcon){
            return this.$t('myrecipe_once_a_week');
          }else{
            return this.$t('myrecipe_weekly');
          }
          break;
        case 'Weekly':
          if(this.showIcon){
            return this.$t('myrecipe_once_a_week');
          }else{
            return this.$t('myrecipe_weekly');
          }
          break;
        case 'biweekly':
          return this.$t('myrecipe_biweekly');
          break;
        case 'BiWeekly':
          return this.$t('myrecipe_biweekly');
          break;
        case 'monthly':
          return this.$t('myrecipe_monthly');
          break;
        case 'Monthly':
          return this.$t('myrecipe_monthly');
          break;
        default:
          return '';
      }
    },
    quantity() {
      let price = this.product.recipeSuggestions.value_calculated;
      let hasDecimal = false;
      let splitPrice = price.toString().split('.');
      if (parseFloat(splitPrice[1]) != 0 && splitPrice.length > 1) {
        hasDecimal = true;
      }

      if (this.product.pricing.currency == 'EUR') {
        if (!hasDecimal) {
          return `${parseFloat(price)}`
        } else {
          let roundedPrice = parseFloat(price.toFixed(1)).toString();
          let euroPrice = roundedPrice.split('.');
          let commaPrice = euroPrice[0] + ',' + euroPrice[1];
          return `${commaPrice}`
        }
      }
      if (this.product.pricing.currency == 'JPY') {
        if (!hasDecimal) {
          return `${parseFloat(price)}`
        } else {
          return `${parseFloat(price)}`
        }
      }
      if (this.product.pricing.currency == 'CNY') {
        if (!hasDecimal) {
          return `${parseFloat(price)}`
        } else {
          return `${parseFloat(price.toFixed(1))}`
        }
      }
      if (this.product.pricing.currency == 'AUD') {
        if (!hasDecimal) {
          return `${parseFloat(price)}`
        } else {
          return `${parseFloat(price.toFixed(1))}`
        }
      }

      if (this.product.pricing.currency == 'USD') {
        if (!hasDecimal) {
          return `${parseFloat(price)}`
        } else {
          return `${parseFloat(price.toFixed(1))}`
        }
      }

      if (this.product.pricing.currency == 'GBP') {
        if (!hasDecimal) {
          return `${parseFloat(price)}`
        } else {
          return `${parseFloat(price.toFixed(1))}`
        }
      }
      if (this.product.pricing.currency == 'ILS') {
        if (!hasDecimal) {
          return `${parseFloat(price)}`
        } else {
          return `${parseFloat(price.toFixed(1))}`
        }
      }
  }
}
}
</script>
<style scoped>
.myrecipe-supplement-item:not(:last-of-type) {
  border-bottom: 1px solid var(--rsf-2022-color-16);
}

.item-divider {
  width: 1px;
  background-color: var(--rsf-2022-color-16);
  height: 64px;
}

.myrecipe-supplement-item .thumbnail {
  width: 100px;
  aspect-ratio: 1 / 1;
  object-fit: contain;
}

@media(min-width: 1025px) {
  .lg-show {
    display: inline-block;
  }
}

.frequency-box {
  min-width: 120px;
  max-width: 120px;
}

@media(max-width: 450px) {
  .frequency-box {
    min-width: 90px;
    max-width: 90px;
  }
}

.more-info-icon {
  position: absolute;
  z-index: 2;
  bottom: .5rem;
  right: .5rem;
  pointer-events: none;
}</style>