<template>
<div class="myrecipe-watervolume-card bg-color-white flex flex-column justify-between py3 px2 col-12 border-box">
  <img class="block center mx-auto pb3 water-volume-icon" src="https://redseafish.com/wp-content/uploads/2023/09/icon_volume.svg"/>
  <div class="px1 max-width-1 mx-auto border-box">
    <div class="flex items-center gap2 col-12 mx-auto">
      <input class="rsf-input col-6" type="number" :placeholder="$t('rsf_input_placeholder_volume')" v-model="volumeNumber"/>
      <div class="rsf-select-wrapper relative col-6" style="min-width: 110px">
        <svg :style="`transform: rotate(180deg) translateY(50%)`" width="24" height="24" viewBox="0 0 24 24"
          fill="none">
          <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <select v-model="volumeUnit" class="rsf-select color-14 pointer h5">
          <option value="Liter" selected>{{$t('rsf_unit_liter')}}</option>
          <option value="gallons">{{$t('rsf_unit_gallons')}}</option>
        </select>
      </div>
    </div>
    <p class="color-14 open-sans-regular h6 mt1 flex" style="font-size: 12px; gap: 4px;"><span>*</span>{{ $t('myrecipe_actual_net_water_volume_info') }}</p>
  </div>
<div @mousedown="scaleCard($event)" :class="`rsf-btn rsf-btn-primary color-white bg-color-1 mx-auto hide lg-block mt3 ${volumeNumber != '' ? '' : 'inactive'}`" style="min-width: 0;" @click="setActiveStep(5); setFormData('waterVolume', volumeNumber); setFormData('waterVolumeUnit', volumeUnit)">{{ $t('rsf_btn_label_continue') }}</div>
</div>
</template>

<script>
import axios from 'axios'
// import { EventBus } from '@/event-bus'

export default {
  name: 'Card_WaterVolume',
  props: {
    setFormData: Function,
    setActiveStep: Function,
  },
  data() {
    return {
      volumeNumber: '',
      volumeUnit: 'Liter'
    }
  },
  methods: {
      scaleCard(event) {
        console.log(event)
        const card = event.target.closest('.myrecipe-card-interaction-animation');
        if (card) {
          card.classList.add('clicked');
          
          // Add a mouseup event listener to remove the class when the mouse button is released.
          document.addEventListener('mouseup', function() {
            card.classList.remove('clicked');
          }, { once: true });
        }
      }
    }
}
</script>

<style scoped>
  .myrecipe-watervolume-card{
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
    border: 1px solid var(--rsf-2022-color-12);
  }
  .rsf-water-volume-unit-select, .rsf-water-volume-text-input{
    padding: 0.9535rem 1.75rem;
    background-color: var(--rsf-2022-color-white);
    border-radius: 999px;
    width: 100%;
    border: 1px solid var(--rsf-2022-color-12);
    font-size: var(--h5) !important;
    box-sizing: border-box
  }

  .rsf-water-volume-unit-select-wrapper{
    max-width: 500px;
  }

  .rsf-water-volume-unit-select-wrapper > svg{
    position: absolute; 
    top: 50%;
    right: 1rem;
  }

    @media(max-width: 420px){
    .rsf-water-volume-unit-select{
      padding: 0.9535rem 1.5rem;
      text-overflow: ellipsis;
      padding-right: 2.25rem;
    }
  }

  .myrecipe-countryselect-card{
    width: 100%;
    max-width: 500px;
  }

  .water-volume-icon{
    width: 120px;
    aspect-ratio: 1 / 1;
    object-fit: contain;
  }
</style>
