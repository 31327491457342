<template>
  <div>
    <div ref="tab-switch-wrapper" class="tab-switch-wrapper flex col-12 justify-center bg-color-white py2">
      <div class="myrecipe-tab-switch flex justify-center">
        <div 
          ref="tab-1"
          :class="`myrecipe-tab-pill px2 w-fit-content pointer relative z3 open-sans-bold h6 ${activeTab == 1 ? 'color-white' : 'color-black'}`"
          @click="updateActiveTab(1)">{{slide1Heading}}</div>
        <div 
          ref="tab-2"
          :class="`myrecipe-tab-pill px2 w-fit-content pointer relative z3 open-sans-bold h6 ${activeTab == 2 ? 'color-white' : 'color-black'}`"
          @click="updateActiveTab(2)">{{slide2Heading}}</div>
        <div ref="tab-indicator" :class="`active-tab-indicator ${activeTab == 2 ? 'move-right' : ''}`"></div>
      </div>
    </div>
    <slot name="additional-top-content"></slot>
    <div class="myrecipe-tab-wrapper gridbox col-12 overflow-hidden" :style="`height:${activeTab == 1 ? slideHeight1 : slideHeight2}px`">
      <div ref="recipeSlide1" :class="`myrecipe-tab-slide slide-1 gridbox-element ${activeTab == 1 ? 'active' : ''}`">
        <slot name="slide-1"></slot>
      </div>
      <div ref="recipeSlide2" :class="`myrecipe-tab-slide slide-2 gridbox-element ${activeTab == 2 ? 'active' : ''}`">
        <slot name="slide-2"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
export default {
  name: 'Tab_Carousel',
  props: {
    slide1Heading: String, 
    slide2Heading: String,
    activeTab: Number,
    updateActiveTab: Function,
    

  },
  data(){
    return {
      slideHeight1: 0,
      slideHeight2: 0,
    }
  },
  created(){
    EventBus.$on('update_slide_height', (payload) => {
      console.log('received update slide height')
      if(payload.id == 'recipe-slide-1'){
        this.slideHeight1 = payload.height
      }else{
        this.slideHeight2 = payload.height
      }
    })
  },
  mounted() {
    setTimeout(() => {
      this.$refs['tab-indicator'].style.width = this.$refs['tab-' + this.activeTab].clientWidth + 'px'
      console.log(this.$refs['tab-indicator'].style.width);

    }, 300)
    window.addEventListener('resize', () => {
      this.$refs['tab-indicator'].style.width = this.$refs['tab-' + this.activeTab].clientWidth + 'px'
      if (this.activeTab == 2) {
        this.$refs['tab-indicator'].style.left = `calc(100% - ${this.$refs['tab-' + this.activeTab].clientWidth + 'px'})`
      } else {
        this.$refs['tab-indicator'].style.left = 0
      }
    })
  },
  methods: {
    moveIndicator(tab) {
      this.$refs['tab-indicator'].style.width = this.$refs['tab-' + tab].clientWidth + 'px'
      if (tab == 2) {
        this.$refs['tab-indicator'].style.left = `calc(100% - ${this.$refs['tab-' + tab].clientWidth + 'px'})`
      } else {
        this.$refs['tab-indicator'].style.left = 0
      }
    },
  },
  watch:{
    activeTab(newVal, oldVal){
      if(oldVal == newVal){return;}
      this.$refs['tab-indicator'].style.width = this.$refs['tab-' + newVal].clientWidth + 'px'
      if (newVal == 2) {
        this.$refs['tab-indicator'].style.left = `calc(100% - ${this.$refs['tab-' + newVal].clientWidth + 'px'})`
      } else {
        this.$refs['tab-indicator'].style.left = 0
      }
    },
  },
}
</script>
<style scoped>
.myrecipe-tab-switch {
  background-color: var(--rsf-2022-color-12);
  width: fit-content;
  margin: 0 auto;
  border-radius: 999px;
  position: relative;
}

.active-tab-indicator {
  display: block;
  position: absolute;
  width: 30px;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: black;
  border-radius: 999px;
  transition: .3s ease;
  border: 2px solid var(--rsf-2022-color-12);
  box-sizing: border-box;
}

.myrecipe-tab-pill{
  padding-top: 10px;
  padding-bottom: 10px;
}


.myrecipe-tab-slide {
  transition: .4s ease;
  opacity: 0;
  overflow: hidden;
}

.myrecipe-tab-slide.slide-1 {
  transform: translateX(-100%);
}

.myrecipe-tab-slide.slide-1.active {
  transform: translateX(0);
}

.myrecipe-tab-slide.slide-2 {
  transform: translateX(100%);
}

.myrecipe-tab-slide.slide-2.active {
  transform: translateX(0);

}

.myrecipe-tab-slide.active {
  opacity: 1;
}

.tab-switch-wrapper {
  position: sticky;
  top: var(--navbarHeight);
  z-index: 99;
}

.myrecipe-tab-wrapper{
  transition: height .5s ease;
  transition-delay: .3s;
}
</style>