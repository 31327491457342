<template>
  <div>
    <div ref="tab-switch-wrapper" class="tab-switch-wrapper flex col-12 justify-center bg-color-white py2">
      <div class="myrecipe-tab-switch flex justify-center">
        <div 
          ref="tab-1"
          :class="`myrecipe-tab-pill px2 lw-fit-content pointer relative z3 open-sans-bold h6 ${activeTab == 1 ? 'color-white' : 'color-black'}`"
          @click="activeTab = 1; moveIndicator(1)">{{slide1Heading}}</div>
        <div 
          ref="tab-2"
          :class="`myrecipe-tab-pill px2 w-fit-content pointer relative z3 open-sans-bold h6 ${activeTab == 2 ? 'color-white' : 'color-black'}`"
          @click="activeTab = 2; moveIndicator(2)">{{slide2Heading}}</div>
        <div ref="tab-indicator" :class="`active-tab-indicator ${activeTab == 2 ? 'move-right' : ''}`"></div>
      </div>
    </div>
    <slot name="additional-top-content"></slot>
    <div class="myrecipe-tab-wrapper gridbox col-12 overflow-hidden">
      <div :class="`myrecipe-tab-slide slide-1 gridbox-element ${activeTab == 1 ? 'active' : ''}`">
        <slot name="slide-1"></slot>
      </div>
      <div :class="`myrecipe-tab-slide slide-2 gridbox-element ${activeTab == 2 ? 'active' : ''}`">
        <slot name="slide-2"></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Tab_Carousel',
  props: {
    slide1Heading: String, 
    slide2Heading: String,
  },
  data(){
    return {
      activeTab: 1
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs['tab-indicator'].style.width = this.$refs['tab-1'].clientWidth + 'px'
    }, 300)
  },
  methods: {
    moveIndicator(tab) {
      this.$refs['tab-indicator'].style.width = this.$refs['tab-' + tab].clientWidth + 'px'
      if (tab == 2) {
        this.$refs['tab-indicator'].style.left = `calc(100% - ${this.$refs['tab-' + tab].clientWidth + 'px'})`
      } else {
        this.$refs['tab-indicator'].style.left = 0
      }
    }
  },
  watch: {
  }
}
</script>
<style scoped>
.myrecipe-tab-switch {
  background-color: var(--rsf-2022-color-12);
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 2rem;
  border-radius: 999px;
  position: relative;
}

.active-tab-indicator {
  display: block;
  position: absolute;
  width: 30px;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: black;
  border-radius: 999px;
  transition: .3s ease;
  border: 2px solid var(--rsf-2022-color-12);
  box-sizing: border-box;
}

.myrecipe-tab-pill{
  padding-top: 10px;
  padding-bottom: 10px;
}

.myrecipe-tab-slide {
  transition: .3s ease;
  opacity: 0;
}

.myrecipe-tab-slide.slide-1 {
  transform: translateX(-100%);
}

.myrecipe-tab-slide.slide-1.active {
  transform: translateX(0);
}

.myrecipe-tab-slide.slide-2 {
  transform: translateX(100%);
}

.myrecipe-tab-slide.slide-2.active {
  transform: translateX(0);
}

.myrecipe-tab-slide.active {
  opacity: 1;
}

.tab-switch-wrapper {
  position: sticky;
  top: 0;
  z-index: 99;
}
</style>