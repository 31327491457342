<template>
  <div class="myrecipe-card pt3 pb3 px2 bg-color-white col-12 max-width-3 border-box">
    <h2 class="center h1 helvetica-bold mb1 px2 border-box">{{ formData.waterVolume }} {{ formData.waterVolumeUnit == 'gallons' ? $t('rsf_unit_gallons') : $t('rsf_unit_liter') }} {{ recipeData ? recipeData.recipeTankType.name : ''}}</h2>
    <p class="center color-14 open-sans-regular h4 lg-h3 mb4">{{ $t('rsf_recipe_growth_rate') }}: {{ formData.reefingGoal.growth }} | {{ $t('rsf_recipe_coloration') }}: {{ formData.reefingGoal.color }}</p>
    <!-- // 1: MR CPS
      // 2: MR RSS
      // 3: SPS CPS
      // 4: SPS RSS
      // 5. FT CPS
      // 6: SO CPS -->
    <img v-if="recipeCardImage == 1" class="block mx-auto contain col-12 px3 border-box" style="max-height: 460px;" src="https://static.redseafish.com/wp-content/uploads/2023/12/07095245/mixed-cp-salt.jpg"/>
    <img v-if="recipeCardImage == 2" class="block mx-auto contain col-12 px3 border-box" style="max-height: 460px;" src="https://static.redseafish.com/wp-content/uploads/2023/12/07095246/mixed-rs-salt.jpg"/>
    <img v-if="recipeCardImage == 3" class="block mx-auto contain col-12 px3 border-box" style="max-height: 460px;" src="https://static.redseafish.com/wp-content/uploads/2023/12/07095250/sps-cp-salt.jpg"/>
    <img v-if="recipeCardImage == 4" class="block mx-auto contain col-12 px3 border-box" style="max-height: 460px;" src="https://static.redseafish.com/wp-content/uploads/2023/12/07095251/sps-rs-salt.jpg"/>
    <img v-if="recipeCardImage == 5" class="block mx-auto contain col-12 px3 border-box" style="max-height: 460px;" src="https://static.redseafish.com/wp-content/uploads/2023/12/07095244/frags-cp-bucket.jpg"/>
    <img v-if="recipeCardImage == 6" class="block mx-auto contain col-12 px3 border-box" style="max-height: 460px;" src="https://static.redseafish.com/wp-content/uploads/2023/12/07095248/soft-cp-bucket.jpg"/>
    <p class="center color-black open-sans-bold lg-h4 h5 mb2 mt3">{{ $t('myrecipe_recipecard_aim_for') }}</p>
    <div class="bg-color-12 h1px col-21"></div>
    <div class="lg-flex col-12 pt3">
      <div
        class="col-12 parameter-cell px2 flex lg-flex-column justify-between border-box"
      > 
        <p class="lg-h4 h5 center open-sans-semibold">{{ $t('myrecipe_chemical_heading_salinity') }}</p>
        <div class="lg-hide col-12 row-line"></div>
        <div class="flex lg-flex-column">
          <p class="h5 center open-sans-regular color-14">{{ recipeData.recipeHeader.salinity_number }}</p>
          <p class="h5 lg-h6 center open-sans-regular color-14">{{ recipeData.recipeHeader.salinity_unit }}</p>
        </div>
      </div>
      <div
        class="col-12 parameter-cell px2 flex lg-flex-column justify-between border-box"
      > 
        <p class="lg-h4 h5 center open-sans-semibold">{{ $t('myrecipe_chemical_heading_calcium') }}</p>
        <div class="lg-hide col-12 row-line"></div>
        <div class="flex lg-flex-column">
          <p class="h5 center open-sans-regular color-14">{{ recipeData.recipeHeader.calcium_number }}</p>
          <p class="h5 lg-h6 center open-sans-regular color-14">{{ recipeData.recipeHeader.calcium_unit }}</p>
        </div>
      </div>
      <div
        class="col-12 parameter-cell px2 flex lg-flex-column justify-between border-box"
      > 
        <p class="lg-h4 h5 center open-sans-semibold">{{ $t('myrecipe_chemical_heading_magnesium') }}</p>
        <div class="lg-hide col-12 row-line"></div>
        <div class="flex lg-flex-column">
          <p class="h5 center open-sans-regular color-14">{{ recipeData.recipeHeader.magnesium_number }}</p>
          <p class="h5 lg-h6 center open-sans-regular color-14">{{ recipeData.recipeHeader.magnesium_unit }}</p>
        </div>
      </div>
      <div
        class="col-12 parameter-cell px2 flex lg-flex-column justify-between border-box"
      > 
        <p class="lg-h4 h5 center open-sans-semibold">{{ $t('myrecipe_chemical_heading_alkalinity') }}</p>
        <div class="lg-hide col-12 row-line"></div>
        <div class="flex lg-flex-column">
          <p class="h5 center open-sans-regular color-14">{{ recipeData.recipeHeader.alkalinity_number }}</p>
          <p class="h5 lg-h6 center open-sans-regular color-14">{{ recipeData.recipeHeader.alkalinity_unit }}</p>
        </div>
      </div>
      <div
        class="col-12 parameter-cell px2 flex lg-flex-column justify-between border-box"
      > 
        <p class="lg-h4 h5 center open-sans-semibold">{{ $t('myrecipe_chemical_heading_nitrate') }}</p>
        <div class="lg-hide col-12 row-line"></div>
        <div class="flex lg-flex-column">
          <p class="h5 center open-sans-regular color-14">{{ recipeData.recipeHeader.nitrate_number }}</p>
          <p class="h5 lg-h6 center open-sans-regular color-14">{{ recipeData.recipeHeader.nitrate_unit }}</p>
        </div>
      </div>
      <div
        class="col-12 parameter-cell px2 flex lg-flex-column justify-between border-box"
      > 
        <p class="lg-h4 h5 center open-sans-semibold">{{ $t('myrecipe_chemical_heading_phosphate') }}</p>
        <div class="lg-hide col-12 row-line"></div>
        <div class="flex lg-flex-column">
          <p class="h5 center open-sans-regular color-14">{{ recipeData.recipeHeader.phosphate_number }}</p>
          <p class="h5 lg-h6 center open-sans-regular color-14">{{ recipeData.recipeHeader.phosphate_unit }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RecipeCard',
  props: {
    recipeData: Object,
    formData: Object,
  },
  computed: {
    recipeCardImage (){
      // 1: MR CPS
      // 2: MR RSS
      // 3: SPS CPS
      // 4: SPS RSS
      // 5. FT CPS
      // 6: SP CPS

      switch(this.recipeData.recipeHeader.id){
        case '10': case '11': case '19': case '20':
          return 1;
        case '12': case '21':
          return 2;
        case '13': case '22': 
          return 3;
        case '14': case '15': case '23': case '24':
          return 4; 
        case '17': case '25': 
          return 5;
        case '18': case '26': 
          return 6;
        default: 
          return 1;
      }
    }
  }
}
</script>
<style scoped>
.myrecipe-card{
  border-radius: 8px;
  border: 1px solid var(--rsf-2022-color-12);
  margin: 0 auto; 

}

.parameter-cell{
  position: relative;
}
.parameter-cell:not(:last-of-type)::after{
  display: block; 
  position: absolute;
  content: '';
  width: 1px;
  height: 80%;
  background-color: var(--rsf-2022-color-12);
  right: 0; 
  top: 50%; 
  transform: translateY(-50%); 
}

@media(max-width: 1024px){
  .row-line{
    border-bottom: 2px dotted var(--rsf-2022-color-12);
  }
  .parameter-cell::after, 
  .parameter-cell:not(:last-of-type)::after{
    display: none; 
  }
  .parameter-cell{
    align-items: baseline;
  }

  .parameter-cell:not(:last-of-type){
    margin-bottom: 24px;
  }

  .parameter-cell .open-sans-semibold{
    font-family: 'Open Sans Bold' !important;
  }
}
</style>