<template>
  <div :class="`flex items-center ${step.step == 1 ? '' : 'flex-grow'}`">
    <div :class="`myrecipe-progress-bar-step-line  ${step.step <= activeStep ?  'active' : ''}`" v-if="step.step != 1"></div>
    <div :class="`myrecipe-progress-bar-step-dot ${step.step <= activeStep ?  'active' : ''}  ${step.step == activeStep ?  'active-step' : ''} ${step.step >= activeStep ?  'pointer-events-none' : 'pointer'}`" @click="setActiveStep(step.step)">
      <!-- <p :class="`myrecipe-progress-bar-step-title block h6 open-sans-regular ${step.step <= activeStep ?  'active' : ''} ${step.step == activeStep ?  'active-step' : ''}`" >{{ computedProgressBarTitle }}</p> -->
      <svg v-if="activeStep > step.step" class="myrecipe-progress-bar-step-checkmark" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 2.8015L3.01592 4.81742L6.83333 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProgressBatStep',
  props: {
    step: Object,
    activeStep: Number,
    setActiveStep: Function,
    formData: Object
  },
  computed: {
    computedProgressBarTitle(){
      switch(this.step.step){
        case 1: 
          if(this.activeStep > 1){
            return this.formData.tankType.name
          }else{
            return this.step.progressBarTitle
          }
        case 2: 
          if(this.activeStep > 2){
            return this.$t('rsf_recipe_growth_rate') + ': ' + this.formData.reefingGoal.growth + ' ' + this.$t('rsf_recipe_coloration') + ': ' +  this.formData.reefingGoal.color
          }else{
            return this.step.progressBarTitle
          }
        case 3:
          if(this.activeStep > 3){
            return this.formData.country.countryname
          }else{
            return this.step.progressBarTitle
          }
        case 4:
          if(this.activeStep > 4){
            return this.formData.waterVolume + ' ' + this.formData.waterVolumeUnit
          }else{
            return this.step.progressBarTitle
          }
        case 5:
          if(this.activeStep > 5){
            return `${this.formData.refugium ? this.$t('rsf_label_with_refugium') : this.$t('rsf_label_without_refugium')}`
          }else{
            return this.step.progressBarTitle
          }
        default: 
          return this.step.progressBarTitle
      }
    }
  }
}
</script>
<style scoped>
    .myrecipe-progress-bar-step-dot{
      width: 14px; 
      aspect-ratio: 1 / 1;
      background-color: var(--rsf-2022-color-13);
      border-radius: 999px;
      transition: .3s ease;
      position: relative;
      flex-shrink: 0; 
    }
    .myrecipe-progress-bar-step-dot.active{
      background-color: var(--rsf-2022-color-3);
    }
    .myrecipe-progress-bar-step-dot.active.active-step{
      transition-delay: .5s;

    }

    .myrecipe-progress-bar-step-dot:after{
      content: '';
      width: 5px; 
      height: 5px;
      border-radius: 999px;
      background-color: white; 
      display: block; 
      position: absolute; 
      top: 50%; 
      left: 50%; 
      transform: translate(-50%, -50%) scale(0);
      transition: .5s ease;
    }

    .myrecipe-progress-bar-step-dot.active-step:after{
      transform: translate(-50%, -50%) scale(1);
      transition-delay: .5s;
    }


    .myrecipe-progress-bar-step-line{
      height: 2px;
      width: 100%; 
      background-color: var(--rsf-2022-color-13);
      flex-grow: 1;
      position: relative;
      overflow: hidden;
    }
    .myrecipe-progress-bar-step-line::after{
      content: '';
      display: block;
      position: absolute; 
      top: 0; 
      left: 0;
      height: 100%; 
      width: 100%;
      background-color: var(--rsf-2022-color-3);
      transform:translateX(-100%);
      transition: .5s ease;
    }
    .myrecipe-progress-bar-step-line.active::after{
      transform: translateX(0);
    }

    .myrecipe-progress-bar-step-title{
      position: absolute; 
      bottom: 2rem;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      line-height: 1.1;
      transition: .2s ease;
    }

    .myrecipe-progress-bar-step-title.active{
      color: var(--rsf-2022-color-3);
    }
    .myrecipe-progress-bar-step-title.active-step{
      transition-delay: .5s;
    }

    .myrecipe-progress-bar-step-checkmark{
      position: absolute; 
      top: 50%;
      left: 50%; 
      transform: translate(-50%, -50%);
    }
</style>