<template>
  <div class="myrecipe-feature-card bg-color-12 col-12 border-box gridbox relative overflow-hidden">
    <img class="gridbox-element z1" :src="require('../assets/images/' + imgSrc)"/>
    <div :class="`z2 p3 ${textColor}`">
      <h3 class="lg-h0 h1 open-sans-bold center">{{ heading }}</h3>
      <p class="open-sans-regular center h3 mb2">{{ subline }}</p>
      <a :href="link" :class="`rsf-btn rsf-btn-tertiary mx-auto open-sans-semibold h5 ${btnColor}`">{{ linkText }}</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FeatureCard',
  props: {
    heading: String,
    subline: String,
    linkText: String, 
    link: String,
    textColor: String,
    btnColor: String,
    imgSrc: String
  }
}
</script>
<style scoped>
.myrecipe-feature-card{
  border-radius: 8px;
  min-height: 550px;
  box-shadow: 0 4px 18px 0 rgba(0,0,0,0.1)
}
.myrecipe-feature-card img{
  position: absolute;
  left: 0; 
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>