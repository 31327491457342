<template>
  <div :class="`myrecipe-progress-bar flex items-center justify-center col-12 max-width-3 px4 border-box pt4 ${activeStep == 6 ? 'op0 pointer-events-none' : ''}`">
    <ProgressBarStep
      v-for="step in formSteps"
      :key="step.step"
      :step="step"
      :activeStep="activeStep"
      :setActiveStep="setActiveStep"
      :formData="formData"
    />
  </div>
</template>

<script>
import ProgressBarStep from './ProgressBarStep.vue';
export default {
  name: 'ProgressBar',
  props: {
    formSteps: Array,
    activeStep: Number,
    setActiveStep: Function,
    formData: Object
  },
  components: {
    ProgressBarStep
  }
}
</script>
<style>
</style>