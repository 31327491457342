<template>
  <FormStepWrapper
    :stepNumber="step.step"
    :activeStep="activeStep"
  >
    <h2 class="myrecipe-formstep-title center helvetica-bold h1 mx-auto max-width-2 mb1 px3 border-box">{{ formData.tankType.id == '6' ? $t('rsf_formStep_heading_02_mr') : $t('rsf_formStep_heading_02_sps') }}</h2>
    <p class="helvetica-roman color-14 h2 center max-width-3 px3 mx-auto border-box mb3">{{ $t('rsf_formStep_subtitle_02') }}</p>
    <div class="flex lg-flex-row flex-column gap2 justify-center col-12 px2 border-box mobile-cards">
      <Card_Option
        v-for="(card, index) in step.cards"
        v-if="formData.tankType.id == card.tankTypeGoalId"
        :key="index"
        :card="card"
        :setActiveStep="setActiveStep"
        :setFormData="setFormData"
      />
    </div>
  </FormStepWrapper>
</template>

<script>

import FormStepWrapper from './FormStepWrapper.vue'
import Card_Option from './Card_Option.vue'
import MobileCarousel from './MobileCarousel.vue'

export default {
  name: 'FormStep_Objective',
  props: {
    step: Object,
    activeStep: Number,
    setActiveStep: Function,
    setFormData: Function,
    formData: Object
  },
  components: {
    Card_Option,
    FormStepWrapper,
    MobileCarousel
}
}
</script>
<style scoped>
@media(min-width: 1025px){
  .flex.lg-flex-row{
    flex-direction: row;
  }
}
@media(max-width: 1024px){
  .mobile-cards span, .mobile-cards p{
      color: var(--rsf-2022-color-14);
      font-family: 'Open Sans Regular', sans-serif !important;
  }
}
</style>