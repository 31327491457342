<template>
  <div class="bg-color-11">
    <div id="myrecipe-wizard" class="myrecipe-wizard border-box">
      <div class="gridbox">
      <FormStep_TankType
        :step="tankTypes"
        :setActiveStep="setActiveStep"
        :setFormData="setFormData"
        :activeStep="activeStep"
      />
      <FormStep_Objective
        :step="reefingGoals"
        :setActiveStep="setActiveStep"
        :setFormData="setFormData"
        :activeStep="activeStep"
        :formData="formData"
      />
      <FormStep_Country 
        :step="yourCountry"
        :setActiveStep="setActiveStep"
        :setFormData="setFormData"
        :activeStep="activeStep"
        :siteLanguage="siteLanguage"
        :updateCountry="updateCountry"
        :selectedCountry="selectedCountry"
      />
      <FormStep_WaterVolume 
        :step="waterVolume"
        :setActiveStep="setActiveStep"
        :setFormData="setFormData"
        :activeStep="activeStep"
      />
      <FormStep_Refugium 
        :step="refugium"
        :setActiveStep="setActiveStep"
        :setFormData="setFormData"
        :activeStep="activeStep"
      />
      <FormStep_Loader 
        :setActiveStep="setActiveStep"
        :setFormData="setFormData"
        :activeStep="activeStep"
        :formData="formData"
      />
      </div>
      <ProgressBar 
        :formSteps="formSteps"
        :activeStep="activeStep"
        :setActiveStep="setActiveStep"
        :formData="formData"
      />
    </div>
  </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue'
import FormStep_TankType from './FomrStep_TankType.vue'
import FormStep_Objective from './FomrStep_Objective.vue'
import FormStep_Country from './FomrStep_Country.vue'
import FormStep_WaterVolume from './FomrStep_WaterVolume.vue'
import FormStep_Refugium from './FomrStep_Refugium.vue'
import FormStep_Loader from './FormStep_Loader.vue'
import { EventBus } from '@/event-bus'

export default {
  name: 'MyRecipe_Wizard',
  components: {
    ProgressBar,
    FormStep_TankType,
    FormStep_Objective,
    FormStep_Country,
    FormStep_WaterVolume,
    FormStep_Refugium,
    FormStep_Loader
},
  props: {
    selectedCountry: Object,
    siteLanguage: String, 
    activeStep: Number,
    formSteps: Array, 
    formData: Object,
    setActiveStep: Function,
    setFormData: Function, 
    updateCountry: Function,
    tankTypes: Object, 
    reefingGoals: Object,
    yourCountry: Object,
    waterVolume: Object,
    refugium: Object
  },
  created(){
    this.setActiveStep(1);
  },
  mounted(){
    document.getElementById('myrecipe-wizard').classList.remove('myrecipe-fade-out');
    EventBus.$emit('reset_recipe_data');
  }
}
</script>
<style scoped>
  .footer{
    display: none !important;
  }
</style>