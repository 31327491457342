<template>
  <div class="myrecipe-refugium-card bg-color-white flex flex-column justify-between py3 px2 border-box mx-auto">
    <img class="block center mx-auto pb3 refugium-card-img" src="https://redseafish.com/wp-content/uploads/2023/09/icon_algae.svg"/>
    <div class="max-width-1 mx-auto px1 col-12 border-box">
      <div class="md-flex flex-column items-center gap2 max-width-1 col-12 mx-auto">
        <div class="rsf-select-wrapper relative col-12">
          <svg :style="`transform: rotate(180deg) translateY(50%)`" width="24" height="24" viewBox="0 0 24 24"
            fill="none">
            <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <select v-model="refugium" class="rsf-select color-14 pointer h5">
            <option value="0">{{$t('rsf_label_without_refugium')}}</option>
            <option value="1" selected>{{$t('rsf_label_with_refugium')}}</option>
          </select>
        </div>
      </div>
      <p class="color-14 open-sans-regular mx-auto mt1 col-12" style="font-size: 12px;">{{ $t('myrecipe_refugium_addition') }}</p>
    </div>
  <div  @mousedown="scaleCard($event)" :class="`rsf-btn rsf-btn-primary color-white bg-color-1 mx-auto hide lg-block mt3 ${refugium != '' ? '' : 'inactive'}`" style="min-width: 0;" @click="setFormData('refugium', refugium); setActiveStep(6)">{{ $t('rsf_btn_label_get_recipe') }}</div>
  </div>
</template>

<script>

export default {
  name: 'Card_Refugium',
  props: {
    card: Object,
    setActiveStep: Function,
    setFormData: Function
  },
  data(){
    return {
      refugium: '0'
    }
  },
  methods: {
      scaleCard(event) {
        console.log(event)
        const card = event.target.closest('.myrecipe-card-interaction-animation');
        if (card) {
          card.classList.add('clicked');
          
          // Add a mouseup event listener to remove the class when the mouse button is released.
          document.addEventListener('mouseup', function() {
            card.classList.remove('clicked');
          }, { once: true });
        }
      }
    }
}
</script>
<style scoped>
  .myrecipe-refugium-card{
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
    border: 1px solid var(--rsf-2022-color-12);
  }
  .rsf-water-volume-unit-select, .rsf-water-volume-text-input{
    padding: 0.9535rem 1.75rem;
    background-color: var(--rsf-2022-color-white);
    border-radius: 999px;
    width: 100%;
    border: 1px solid var(--rsf-2022-color-12);
    font-size: var(--h5) !important;
    box-sizing: border-box
  }

  .rsf-water-volume-unit-select-wrapper{
    max-width: 500px;
  }

  .rsf-water-volume-unit-select-wrapper > svg{
    position: absolute; 
    top: 50%;
    right: 1rem;
  }

  .refugium-card-img{
    width: 120px;
    aspect-ratio: 1 / 1;
    object-fit: contain;
  }
</style>