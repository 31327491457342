<template>
  <section :class="`myrecipe-parameter-section bg-color-white`">
    <div :class="`parameter-section-head p2  ${color}`">
      <h4 class="center lg-h3 h4 color-white helvetica-bold">{{ heading }}</h4>
    </div>
    <slot></slot>
    <div v-if="isocode != 'XX'" class="parameter-section-bottom bg-color-16 p2">
      <div class="open-sans-regular color-14 lg-h5 h6 text-left sm-text-right flex justify-between-sm gap1 items-baseline justify-end"><p>{{ $t('myrecipe_avg_cost') }}</p> <p class="open-sans-bold color-14">{{ formatCurrency(currency, price) }}</p></div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ParameterSection',
  props: {
    color: String,
    heading: String,
    currency: String,
    price: Number,
    isocode: String
  },
  methods: {
    formatCurrency(currency, price) {
      let hasDecimal = false;
      let splitPrice = price.toString().split('.');
      if (parseFloat(splitPrice[1]) != 0 && splitPrice.length > 1) {
        hasDecimal = true;
      }

      if (currency == 'EUR') {
        if (!hasDecimal) {
          return `€${parseFloat(price)}`
        } else {
          let roundedPrice = parseFloat(price).toFixed(2).toString();
          let euroPrice = roundedPrice.split('.');
          let commaPrice = euroPrice[0] + ',' + euroPrice[1];
          return `€${commaPrice}`
        }
      }
      if (currency == 'JPY') {
        if (!hasDecimal) {
          return `¥${parseFloat(price)}`
        } else {
          return `¥${parseFloat(price)}`
        }
      }
      if (currency == 'AUD') {
        if (!hasDecimal) {
          return `$${parseFloat(price)}`
        } else {
          return `$${parseFloat(price).toFixed(2)}`
        }
      }
      if (currency == 'CNY') {
        if (!hasDecimal) {
          return `¥${parseFloat(price)}`
        } else {
          return `¥${parseFloat(price).toFixed(2)}`
        }
      }

      if (currency == 'USD') {
        if (!hasDecimal) {
          return `$${parseFloat(price)}`
        } else {
          return `$${parseFloat(price).toFixed(2)}`
        }
      }

      if (currency == 'GBP') {
        if (!hasDecimal) {
          return `£${parseFloat(price)}`
        } else {
          return `£${parseFloat(price).toFixed(2)}`
        }
      }
      if (currency == 'ILS') {
        if (!hasDecimal) {
          return `₪${parseFloat(price)}`
        } else {
          return `₪${parseFloat(price).toFixed(2)}`
        }
      }
    },
  }
}
</script>
<style scoped>
  .myrecipe-parameter-section{
    border-radius: 12px;
    border: 1px solid var(--rsf-2022-color-16);
    overflow:hidden;
    /* box-shadow: 0 8px 18px 0 #00163F20; */
  }
  .parameter-section-head.rainbow{
    background: linear-gradient(90deg, #17F470, #16C7FF, #6C59FF, #F142FF, #FF1C61);
  }
  .parameter-section-head.violet{
    background: linear-gradient(90deg, #A423F3 0%,#7D2FFE 100%);
  }
  .parameter-section-head.blue{
    background: linear-gradient(90deg, #4ABEFF 0%,#45A6FF 100%);
  }
  .parameter-section-head.pink{
    background: linear-gradient(90deg, #FF3EB2 0%,#FF3ED4 100%);
  }
  .parameter-section-head.green{
    background: linear-gradient(90deg, #ADEA00 0%,#66E900 100%);
  }
  .parameter-section-head.yellow{
    background: linear-gradient(90deg, #FFD600 0%,#FFB800 100%);
  }
  .parameter-section-head.darkblue{
    background: linear-gradient(90deg, #3B5AEA   0%,#472CF5 100%);
  }
  .text-right{
    text-align: right;
  }

  @media(max-width: 40em){
    .justify-between-sm.justify-end{
      justify-content: space-between;
    } 
  }

  @media(max-width: 1024px){
    .parameter-section-bottom p{
      color: var(--rsf-2022-color-14);
    }
  }
  
</style>