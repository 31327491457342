<template>
  <div class="pt2" :id="slideInnerId">
    <div class="flex gap2 items-start lg-mb5 mb4 refugium-info-box">
      <img src="https://redseafish.com/wp-content/uploads/2023/09/icon_bulb.svg" />
      <p class="open-sans-regular h5 color-14">
        {{recipeData.recipeHeader.recipeInfoText}}
      </p>
    </div>
    
    <section class="lg-mb5 mb4">
      <h3 class="sm-h1 h3 helvetica-bold mb2 center">{{$t('myrecipe_salt_headline')}}</h3>
      <p class="open-sans-regular h5 color-14 mb3 center">{{ recipeData.recommandationsSalts.products[0].RNumber == 'R11230' ? $t('myrecipe_salt_subline') : recipeData.recommandationsSalts.products[0].RNumber == 'R11065' ? $t('myrecipe_red_sea_salt_subline') : recipeData.recommandationsSalts.products[0].Model }}</p>

      <!-- Salt -->
      <ParameterSection 
        v-if="recipeData.recommandationsSalts.products.length > 0"
        :heading="$t('myrecipe_parameters_section_heading_salt')" 
        :color="recipeSalt == 'coralpro' ? 'violet' : 'darkblue'" 
        :currency="recipeData.recommandationsSalts.monthlyPriceCurrency" 
        :price="recipeData.recommandationsSalts.monthlyPrice"
        :isocode="recipeData.isoCode"
      >
        <div class="sm-flex gap3 items-center" 
          v-for="product in recipeData.recommandationsSalts.products"
          :key="product.RNumber"
        >
          <div class="relative w-fit-content h-fit-content mx-auto">
            <img 
              class="pointer block ml-auto mr-auto sm-ml0 sm-mr0 mt3 sm-mt0 contain"
              style="width: 200px; aspect-ratio: 200px;"
              @click="modalRef.open(); fetchProductDescription(product.RNumber)"
              :src="product.images.thumbnailImage" 
            />
            <svg class="more-info-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="9" fill="#18E098"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.52941 10C1.52941 5.32182 5.32182 1.52941 10 1.52941C14.6782 1.52941 18.4706 5.32182 18.4706 10C18.4706 14.6782 14.6782 18.4706 10 18.4706C5.32182 18.4706 1.52941 14.6782 1.52941 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM11.25 5C11.25 5.69036 10.6904 6.25 10 6.25C9.30964 6.25 8.75 5.69036 8.75 5C8.75 4.30964 9.30964 3.75 10 3.75C10.6904 3.75 11.25 4.30964 11.25 5ZM11.2246 8.49808C11.108 7.92848 10.6041 7.5 10 7.5H8.75C8.05964 7.5 7.5 8.05964 7.5 8.75C7.5 9.44036 8.05964 10 8.75 10V15C8.75 15.6904 9.30964 16.25 10 16.25C10.6904 16.25 11.25 15.6904 11.25 15V8.75C11.25 8.66371 11.2413 8.57945 11.2246 8.49808Z" fill="white"/>
            </svg>
          </div>
          <div class="flex flex-column gap1 col-12 py3 px2 sm-px0 border-box">
            <h5 class="open-sans-bold lg-h4 h5 center sm-left-align sm-pr3">{{ recipeData.recommandationsSalts.products[0].RNumber == 'R11230' ? $t('myrecipe_coral_pro_salt_heading') : recipeData.recommandationsSalts.products[0].RNumber == 'R11065' ? $t('myrecipe_red_sea_salt_heading') : recipeData.recommandationsSalts.products[0].Model }}</h5>
            <div class="col-12 h1px bg-color-12 sm-pr3 border-box clip-content-box"></div>
            <p class="open-sans-regular lg-h4 h5 center sm-left-align sm-pr3 mobile-color-black">{{ $t('myrecipe_coral_pro_salt_subline') }}</p>
            <div class="col-12 h1px bg-color-12 sm-pr3 border-box clip-content-box"></div>
            <p class="open-sans-regular color-14 lg-h5 h6 center sm-left-align sm-pr3"><span>*</span> {{ $t('myrecipe_coral_pro_salt_text_1') }} <br>
              <a v-if="siteLanguage != 'cn'" class="color-1" :href="`https://redseafish.com/${siteLanguage != 'en' ? siteLanguage + '/' : ''}blog-item/switching-between-reef-salts/`" rel="noopener" target="_blank" style="text-decoration: none;">{{ $t('myrecipe_coral_pro_salt_text_2') }}</a> 
              <a v-if="siteLanguage == 'cn'" class="color-1" href="http://www.redseafish.cn/switching-between-salts/" rel="noopener" target="_blank" style="text-decoration: none;">{{ $t('myrecipe_coral_pro_salt_text_2') }}</a> 
              {{ $t('myrecipe_coral_pro_salt_text_3') }}
            </p>
          </div>
        </div>
      </ParameterSection>
    </section>

    <section class="lg-mb5 mb4">
      <h3 class="sm-h1 h3 helvetica-bold mb2 center">{{ $t('myrecipe_supplementing_headline') }}</h3>
      <p class="open-sans-regular h5 color-14 mb3 center">{{ recipeData.recipeHeader.section2Subline }}</p>


      <!-- Foundation & Trace Elements -->
      <ParameterSection 
        v-if="recipeData.recommandationsRainbowElements.products.length > 0"
        :heading="$t('myrecipe_parameters_section_heading_foundation_trace_elements')" 
        color="rainbow" 
        :currency="recipeData.recommandationsRainbowElements.monthlyPriceCurrency" 
        :price="recipeData.recommandationsRainbowElements.monthlyPrice"
        :isocode="recipeData.isoCode"
        class="mb3" 
      >
        <div class="sm-flex gap3 items-center bg-color-11">
          <div class="relative w-fit-content mx-auto h-fit-content">
            <img @click="modalRef.open(); fetchProductDescription(recipeData.recommandationsRainbowElements.products[0].RNumber)" class="block ml-auto mr-auto sm-ml0 sm-mr0 pt3 sm-pt0 pointer contain" :src="recipeData.recommandationsRainbowElements.products[0].images.thumbnailImage" style="width: 200px; aspect-ratio: 200px;"/>
            <svg style="bottom: 2rem; right: 1rem"  class="more-info-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="9" fill="#18E098"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.52941 10C1.52941 5.32182 5.32182 1.52941 10 1.52941C14.6782 1.52941 18.4706 5.32182 18.4706 10C18.4706 14.6782 14.6782 18.4706 10 18.4706C5.32182 18.4706 1.52941 14.6782 1.52941 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM11.25 5C11.25 5.69036 10.6904 6.25 10 6.25C9.30964 6.25 8.75 5.69036 8.75 5C8.75 4.30964 9.30964 3.75 10 3.75C10.6904 3.75 11.25 4.30964 11.25 5ZM11.2246 8.49808C11.108 7.92848 10.6041 7.5 10 7.5H8.75C8.05964 7.5 7.5 8.05964 7.5 8.75C7.5 9.44036 8.05964 10 8.75 10V15C8.75 15.6904 9.30964 16.25 10 16.25C10.6904 16.25 11.25 15.6904 11.25 15V8.75C11.25 8.66371 11.2413 8.57945 11.2246 8.49808Z" fill="white"/>
            </svg>
          </div>
          <div class="flex flex-column gap1 col-12 pt3 pb3 px2 sm-px0 border-box">
            <h5 class="open-sans-bold lg-h4 h5 center sm-left-align sm-pr3">{{ $t('myrecipe_foundations_trace_headline') }}</h5>
            <div class="col-12 h1px bg-color-12 sm-pr3 border-box clip-content-box"></div> 
            <p class="open-sans-regular lg-h4 h5 color-black center sm-left-align sm-pr3">{{ $t('myrecipe_foundations_trace_subline') }}</p>
            <div class="col-12 h1px bg-color-12 sm-pr3 border-box clip-content-box"></div>
            <p class="open-sans-regular lg-h5 h6 color-14 center sm-left-align sm-pr3">{{ recipeData.recipeHeader.completeReefCareText }}</p>
          </div>
        </div>
        <SupplementItem 
          v-if="index > 0"
          v-for="(product, index) in recipeData.recommandationsRainbowElements.products"
          :key="product.RNumber"
          :product="product"
          :handleClick="() => {modalRef.open(); fetchProductDescription(product.RNumber)}"
        />
      </ParameterSection>

      <!-- Foundation Elements -->
      <ParameterSection 
        v-if="recipeData.recommandationsFoundationElements.products.length > 0"
        :heading="$t('myrecipe_parameters_section_heading_foundation_replenishments')" 
        color="blue" 
        :currency="recipeData.recommandationsFoundationElements.monthlyPriceCurrency" 
        :price="recipeData.recommandationsFoundationElements.monthlyPrice"
        :isocode="recipeData.isoCode"
        class="mb3" 
      >
        <SupplementItem 
          v-for="product in recipeData.recommandationsFoundationElements.products"
          :key="product.RNumber"
          :product="product"
          :handleClick="() => {modalRef.open(); fetchProductDescription(product.RNumber)}"
        />
      </ParameterSection>

      <!-- Trace Elements -->
      <ParameterSection 
        v-if="recipeData.recommandationsTraceElements.products.length > 0"
        :heading="$t('myrecipe_parameters_section_heading_trace_elements')" 
        color="pink" 
        :currency="recipeData.recommandationsTraceElements.monthlyPriceCurrency" 
        :price="recipeData.recommandationsTraceElements.monthlyPrice"
        :isocode="recipeData.isoCode"
        class="mb3" 
      >
        <SupplementItem 
          v-for="product in recipeData.recommandationsTraceElements.products"
          :key="product.RNumber"
          :product="product"
          :handleClick="() => {modalRef.open(); fetchProductDescription(product.RNumber)}"
        />
      </ParameterSection>

      <!-- Algae Prevention -->
      <ParameterSection 
        v-if="recipeData.recommandationsAlgaePrevention.products.length > 0"
        :heading="$t('myrecipe_parameters_section_heading_algae_prevention')" 
        color="green" 
        :currency="recipeData.recommandationsAlgaePrevention.monthlyPriceCurrency" 
        :price="recipeData.recommandationsAlgaePrevention.monthlyPrice"
        :isocode="recipeData.isoCode"
        class="mb3" 
      >
        <SupplementItem 
          v-for="product in recipeData.recommandationsAlgaePrevention.products"
          :key="product.RNumber"
          :product="product"
          :handleClick="() => {modalRef.open(); fetchProductDescription(product.RNumber)}"
        />
      </ParameterSection>

      <!-- Coral Nutrition -->
      <ParameterSection 
        v-if="recipeData.recommandationsCoralNutrition.products.length > 0"
        :heading="$t('myrecipe_parameters_section_heading_coral_nutrition')" 
        color="yellow" 
        :currency="recipeData.recommandationsCoralNutrition.monthlyPriceCurrency" 
        :price="recipeData.recommandationsCoralNutrition.monthlyPrice"
        :isocode="recipeData.isoCode"
        class="mb2" 
      >
        <SupplementItem 
          v-for="product in recipeData.recommandationsCoralNutrition.products"
          :key="product.RNumber"
          :product="product"
          :handleClick="() => {modalRef.open(); fetchProductDescription(product.RNumber)}"
        />
      </ParameterSection>
    </section>

    <!-- Show both TestKit Arrays -->
    <section class="lg-mb5 mb4">
      <h3 class="sm-h1 h3 helvetica-bold mb2 center">{{ $t('myrecipe_monitoring_headline') }}</h3>
      <p class="open-sans-regular h5 color-14 mb3 center">{{ recipeData.recipeHeader.section3Subline }}</p>
      <div class="border color-12 overflow-hidden" style="border-radius: 8px">
        <SupplementItem 
          v-for="product in weeklyTestsOne"
          :key="product.RNumber"
          :product="product"
          :showIcon="true"
          :handleClick="() => {modalRef.open(); fetchProductDescription(product.RNumber)}"
        />
        <SupplementItem 
          v-for="product in weeklyTestsBi"
          :key="product.RNumber"
          :product="product"
          :showIcon="true"
          :handleClick="() => {modalRef.open(); fetchProductDescription(product.RNumber)}"
        />
        <SupplementItem 
          v-for="product in monthlyTests"
          :key="product.RNumber"
          :product="product"
          :showIcon="true"
          :handleClick="() => {modalRef.open(); fetchProductDescription(product.RNumber)}"
        />
      </div>
    </section>

  </div>
</template>

<script>

import RecipeCard from './RecipeCard.vue';
import ParameterSection from './ParameterSection.vue';
import SupplementItem from './SupplementItem.vue';
import MyRecipe_Modal from './MyRecipe_Modal.vue';
import Tab_Carousel from './Tab_Carousel.vue';
import Form_SendEmail from './Form_SendEmail.vue';
import FeatureCard from './FeatureCard.vue';
import ProductDescription from './ProductDescription.vue';
import { EventBus } from '@/event-bus';

export default {
  name: 'MyRecipe_Result_Slide',
  props: {
    getModalRef:  {
      type: Function,
      default: () => ({})
    },
    fetchProductDescription: Function,
    recipeData: Object,
    slideInnerId: String,
    isWithoutRefugium: Boolean,
    siteLanguage: String
  },
  components: {
    RecipeCard,
    ParameterSection,
    SupplementItem,
    MyRecipe_Modal,
    Tab_Carousel,
    Form_SendEmail,
    FeatureCard,
    ProductDescription,
} ,
  data(){
    return {
      modalRef: null,
    }
  },
  mounted(){
    this.modalRef = this.getModalRef();
    this.observeHeight(); 
  },
  methods: {
    observeHeight() {
      let that = this;
      const resizeObserver = new ResizeObserver(function() {
        console.log("Size changed: " + that.slideInnerId);
        console.log(document.getElementById(that.slideInnerId).clientHeight)
        EventBus.$emit('update_slide_height', {
          id: that.slideInnerId,
          height: document.getElementById(that.slideInnerId).clientHeight
        })
      });

      resizeObserver.observe(document.getElementById(this.slideInnerId));
    }
  },
  computed: {
    recipeSalt(){
      if(
        this.recipeData.recommandationsSalts.products[0].RNumber == 'R11220' ||
        this.recipeData.recommandationsSalts.products[0].RNumber == 'R11226' ||
        this.recipeData.recommandationsSalts.products[0].RNumber == 'R11230'
      ){
        return 'coralpro'
      }else{
        return 'redseasalt'
      }
    },
    weeklyTests(){
      let output = [];
      for(let i = 0; i < this.recipeData.recommandationsTestKits.products.length; i++){
        if(
          this.recipeData.recommandationsTestKits.products[i].recipeSuggestions.frequency == 'weekly' 
          || this.recipeData.recommandationsTestKits.products[i].recipeSuggestions.frequency == 'Weekly' 
          || this.recipeData.recommandationsTestKits.products[i].recipeSuggestions.frequency == 'BiWeekly' 
          || this.recipeData.recommandationsTestKits.products[i].recipeSuggestions.frequency == 'biweekly' 
          || this.recipeData.recommandationsTestKits.products[i].recipeSuggestions.frequency == 'daily'
          || this.recipeData.recommandationsTestKits.products[i].recipeSuggestions.frequency == 'Daily'
        ){
          output.push(this.recipeData.recommandationsTestKits.products[i])
        }
      }
      return output;
    },
    monthlyTests(){
      let output = [];
      for(let i = 0; i < this.recipeData.recommandationsTestKits.products.length; i++){
        if(
          this.recipeData.recommandationsTestKits.products[i].recipeSuggestions.frequency == 'monthly'
          || this.recipeData.recommandationsTestKits.products[i].recipeSuggestions.frequency == 'Monthly'
        ){
          output.push(this.recipeData.recommandationsTestKits.products[i])
        }
      }
      return output;
    },
    weeklyTestsOne(){
      let output = [];
      for(let i = 0; i < this.recipeData.recommandationsTestKits.products.length; i++){
        if(
          this.recipeData.recommandationsTestKits.products[i].recipeSuggestions.frequency == 'weekly' 
          || this.recipeData.recommandationsTestKits.products[i].recipeSuggestions.frequency == 'Weekly' 
        ){
          output.push(this.recipeData.recommandationsTestKits.products[i])
        }
      }
      return output;
    },
    weeklyTestsBi(){
      let output = [];
      for(let i = 0; i < this.recipeData.recommandationsTestKits.products.length; i++){
        if(
          this.recipeData.recommandationsTestKits.products[i].recipeSuggestions.frequency == 'BiWeekly' 
          || this.recipeData.recommandationsTestKits.products[i].recipeSuggestions.frequency == 'biweekly' 
        ){
          output.push(this.recipeData.recommandationsTestKits.products[i])
        }
      }
      return output;
    }
  }
}
</script>
<style scoped>
.more-info-icon{
  position: absolute; 
  z-index: 2; 
  bottom: .5rem; 
  right: .5rem;
  pointer-events: none;
}

.clip-content-box{
  background-clip: content-box;
}
@media(max-width: 1024px){
  .refugium-info-box{
    flex-direction: column; 
    align-items: center;
  }
}

@media(max-width: 639px){
  .mobile-color-black{
    color: var(--rsf-2022-color-black)
  }
}
</style>