<template>
  <div class="myrecipe-formstep-card rsf-card-interaction-animation bg-color-white flex flex-column justify-between pointer" @click="setActiveStep(card.nextStep); setFormData(card.formDataAttribute, card); sendTrackingData()">
    <div class="option-card-flex">
      <img :src="'https://static.redseafish.com/' + card.image_s3path"/>
      <div class="">
        <h3 class="h3 helvetica-bold mb1 mt3 px3 center">{{ card.name }}</h3>
        <div :class="`color-14 h5 open-sans-regular mb3 px3 center md-mb4`" v-html="card.description"></div>
      </div>
    </div>
    <div 
      class="rsf-btn rsf-btn-primary color-white bg-color-1 mx-auto hide lg-block" 
      style="min-width: 0;" 
      @click.stop="
        setActiveStep(card.nextStep); 
        setFormData('reefingGoal', {id: card.id == '5' ? 13 : 14, growth:$t('soft_frag_color_and_growth'), color: $t('soft_frag_color_and_growth')}); 
        setFormData(card.formDataAttribute, card)
        sendTrackingData();
      "
    >
      {{$t('rsf_btn_label_select')}}
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'Card_TankType',
  props: {
    card: Object,
    setActiveStep: Function,
    setFormData: Function
  },
  data(){
    return {
      showTooltip: false,
      permanentTooltip: false
    }
  },
  methods: {
    calcTooltipTipPosition(){
      this.$refs['tooltip-tip'].style.left = (this.$refs['tooltip-icon'].offsetLeft - 10) + 'px'
    },
    sendTrackingData(){
      let url = process.env.VUE_APP_RSF_API + `/public-api/myrecipe/track-tank-selection/${this.card.id}/json`;
      axios.get(url)
        .then(res => {
          //do nothing
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
</script>
<style scoped>
  .myrecipe-formstep-card{
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    width: 262px;
    box-sizing: border-box;
    flex-shrink: 0;
    border: 1px solid var(--rsf-2022-color-12);
    border-radius: 8px;
  }
  .myrecipe-formstep-card img{
    width: 120px;
    aspect-ratio: 1 / 1; 
    object-fit: contain; 
    display: block;
    margin: 0 auto;
  }
  .tooltip-bubble{
    position: absolute; 
    bottom: -1rem;
    right: 0;
    border: 1px solid var(--rsf-2022-color-12);
    box-sizing: border-box;
    padding: 1rem;
    padding-top: .75rem;
    background-color: white;
    transition: .3s ease; 
    opacity: 0; 
    transform: translateY(150%); 
    transition-delay: .3s;
    visibility: hidden;
    border-radius: 8px;
  }

  .tooltip-bubble.active{
    opacity: 1; 
    transform: translateY(100%); 
    pointer-events: all;
    visibility: visible;
  }
  .tooltip-tip{
    position: absolute; 
    top: -1px; 
    left: 2rem;
    height: 20px;
    aspect-ratio: 1 / 1; 
    border-radius: 4px;
    background-color: white;
    transform: rotate(45deg) translateY(-50%);
    box-shadow: -0.75px -0.75px 0 0 var(--rsf-2022-color-12);
  }

  .myrecipe-formstep-card .h5 p{
    font-family: 'Open Sans Regular';
  }

  @media(max-width: 1024px){
    .mobile-cards .option-card-flex{
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }

    .mobile-cards .option-card-flex h3{
      margin-top: 0;
      text-align: left; 
    }
    .mobile-cards .option-card-flex p{
      margin-bottom: 0;
      text-align: left; 
    }
    .mobile-cards .myrecipe-formstep-card{
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
      padding: 1rem 0;
    }
    .mobile-cards .myrecipe-formstep-card .rsf-btn{
      display: none;
    }
    .mobile-cards .myrecipe-formstep-card img{
      margin: 0;
      padding-right: 1rem
    }
    .mobile-cards .myrecipe-formstep-card .flex.lg-flex-column.flex-row-reverse{ 
      gap: 2rem;
    }
  }
</style>