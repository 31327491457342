<template>
  <div class="myrecipe-countryselect-card bg-color-white flex flex-column justify-between px2 py3 col-12 border-box">
      <img class="block center mx-auto pb3" src="https://redseafish.com/wp-content/uploads/2023/09/icon_globe.svg" style="width: 120px; aspect-ratio: 1 / 1; object-fit:contain;"/>
      <div class="px1">
        <CountrySelect 
          :siteLanguage="siteLanguage"
          :updateCountry="updateCountry"
          />
          <p class="color-14 open-sans-regular mt1 col-12 flex" style="font-size: 12px; gap: 4px" v-if="selectedCountry.isocode2 == 'XX'"><span>*</span>{{ $t('myrecipe_other_country_addition') }}</p>
      </div>
    <div @mousedown="scaleCard($event)" :class="`rsf-btn rsf-btn-primary color-white bg-color-1 mx-auto hide lg-block mt4 ${selectedCountry ? '' : 'inactive'}`" style="min-width: 0;" @click="setActiveStep(4); setFormData('country', selectedCountry)">{{$t('rsf_btn_label_select')}}</div>
  </div>
</template>

<script>

import CountrySelect from './CountrySelect.vue';

export default {
    name: "CountrySelectCard",
    props: {
      siteLanguage: String,
      updateCountry: Function,
      card: Object,
      setActiveStep: Function,
      setFormData: Function,
      selectedCountry: Object
    },
    components: { CountrySelect },
    methods: {
      scaleCard(event) {
        console.log(event)
        const card = event.target.closest('.myrecipe-card-interaction-animation');
        if (card) {
          card.classList.add('clicked');
          
          // Add a mouseup event listener to remove the class when the mouse button is released.
          document.addEventListener('mouseup', function() {
            card.classList.remove('clicked');
          }, { once: true });
        }
      }
    }
}
</script>
<style scoped>
  .myrecipe-countryselect-card{
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
    border: 1px solid var(--rsf-2022-color-12);
  }
</style>