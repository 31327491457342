<template>
  <div :class="`myrecipe-form-step col-12 max-width-5 gridbox-element ${activeStep == stepNumber ? 'active' : ''}`">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'FormStepWrapper',
  props: {
    activeStep: Number,
    stepNumber: Number
  },
}
</script>
<style scoped>
  .myrecipe-form-step{
    opacity: 0;
    transition: .5s ease;
    pointer-events: none;
  }
  .myrecipe-form-step.active{
    opacity: 1;
    transition-delay: .6s;
    pointer-events: all;
  }
</style>