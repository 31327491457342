<template>
  <FormStepWrapper
    :stepNumber="6"
    :activeStep="activeStep"
  >
    <h2 class="myrecipe-formstep-title center helvetica-bold h1 mx-auto max-width-2 mb4">{{ $t('myrecipe_loader_title') }}</h2>
    <div class="flex gap2 justify-center col-12 px2 border-box">
      <Card_Loader
        :formData="formData"
      />
    </div>
  </FormStepWrapper>
</template>

<script>

import FormStepWrapper from './FormStepWrapper.vue'
import Card_Loader from './Card_Loader.vue'

export default {
  name: 'FormStep_WaterVolume',
  props: {
    siteLanguage: String,
    activeStep: Number,
    setActiveStep: Function,
    setFormData: Function,
    formData: Object
  },
  components: {
    Card_Loader,
    FormStepWrapper,
}
}
</script>
<style>
</style>