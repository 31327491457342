<template>
  <div class="bg-color-11">
    <router-view
      v-show="itemsLoaded = 2"
      :selectedCountry="selectedCountry"
      :activeStep="activeStep"
      :formSteps="formSteps"
      :formData="formData"
      :setFormData="setFormData"
      :setActiveStep="setActiveStep"
      :updateCountry="updateCountry"
      :tankTypes="tankTypes"
      :reefingGoals="reefingGoals"
      :yourCountry="yourCountry"
      :refugium="refugium"
      :waterVolume="waterVolume"
      :recipeData="recipeData"
      :siteLanguage="siteLanguage"
      :recipeDataWithoutRefugium="recipeDataWithoutRefugium"
    ></router-view>
  </div>
</template>

<script>

import axios from 'axios'
import { EventBus } from './event-bus';

export default {
  name: 'App',
  props: ["siteLanguage"],
  data() {
    return {
      selectedCountry: {},
      activeStep: 1,
      formSteps: [
        {
          step: 1, 
          progressBarTitle: this.$t('rsf_formStep_label_01_tank_type'),
        },
        {
          step: 2, 
          progressBarTitle: this.$t('rsf_formStep_label_02_reefing_goal'),
        },
        {
          step: 3, 
          progressBarTitle: this.$t('rsf_formStep_label_03_your_country'),
        },
        {
          step: 4, 
          progressBarTitle: this.$t('rsf_formStep_label_04_water_volume'),
        },
        {
          step: 5, 
          progressBarTitle: this.$t('rsf_formStep_label_05_refugium'),
        },
      ],

      itemsLoaded: 0,

      tankTypes: {
        step: 1,
        stepTitle: this.$t('rsf_formStep_heading_01'),
        stepSubtitle: this.$t('rsf_formStep_subtitle_01'),
        cards: []
      },
      reefingGoals: {
        step: 2,
        stepTitle: 'Test',
        stepSubtitle: this.$t('rsf_formStep_subtitle_02'),
        cards: []
      },
      yourCountry: {
        step: 3, 
        stepTitle: this.$t('rsf_formStep_heading_03'),
        stepSubtitle: this.$t('rsf_formStep_subtitle_03'),
      },
      waterVolume: {
        step: 4, 
        stepTitle: this.$t('rsf_formStep_heading_04'),
        stepSubtitle: this.$t('rsf_formStep_subtitle_04'),

      },
      refugium: {
        step: 5, 
        stepTitle:this.$t('rsf_formStep_heading_05'),
        stepSubtitle: '',
      },
      formData: {
        tankType: {
          id: ''
        },
        reefingGoal: {},
        country: {},
        waterVolume: '',
        waterVolumeUnit: '',
        refugium: '',
      },
      recipeData: {},
      recipeDataWithoutRefugium: {},

    }
  },
  created() {

    if (this.siteLanguage == '') {
      this.$i18n.locale = 'en';
      this.switchLocale();
    } else {
      this.$i18n.locale = this.siteLanguage;
      this.switchLocale();
    }

    EventBus.$on('fetch_recipe_data', () => {
      this.formData.refugium = '1';
      this.getRecipeData();
    })
    EventBus.$on('fetch_recipe_data_without_refugium', () => {
      this.formData.refugium = '0';
      this.getRecipeData();
    })
    EventBus.$on('reset_recipe_data', () => {
      this.recipeData = {};
      this.recipeDataWithoutRefugium = {};
    })
  }, 
  mounted(){
    this.fetchTankTypes()
    this.fetchReefingGoals()
  },
  methods: {
    switchLocale() {
      var locale = this.$i18n.locale;
      const to = this.$router.resolve({ params: { lang: locale } })
      this.$router.push(to.location).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(err);
        }
      })
    },
    setActiveStep(step){
      console.log('set active step')
      this.activeStep = step
      if(this.activeStep == 6){
        this.transitionToResultPage();
      }
    },
    setFormData(attribute, value){
      console.log('set formData')
      console.log('attribute:')
      console.log(attribute)
      console.log('value')
      console.log(value)
      this.formData[attribute] = value;
    },
    updateCountry(v) {
      this.selectedCountry = v;
    },
    transitionToResultPage(){
      let that = this
      this.formData.siteLanguage = this.siteLanguage;
      this.formData.isoCode = this.selectedCountry.isocode2;
      let payload = {
        lang: this.siteLanguage, 
        isoCode: this.selectedCountry.isocode2,
        recipeConfig: this.formData
      }
      let url =  '/public-api/myrecipe/create-recipe/json'
      axios
        .post(process.env.VUE_APP_RSF_API + url, payload)
        .then((response) => {
          if(payload.recipeConfig.refugium == '1'){
            console.log('with refugium');
            that.recipeData = response.data;
          }else{
            console.log('without refugium');
            that.recipeDataWithoutRefugium = response.data;
          }
          setTimeout(() => {
            that.$router.push({ path: '/' + this.$i18n.locale + '/result'});
          }, 1000);
      })
    },
    getRecipeData(){
      let that = this
      this.formData.siteLanguage = this.siteLanguage;
      this.formData.isoCode = this.selectedCountry.isocode2;
      let payload = {
        lang: this.siteLanguage, 
        isoCode: this.selectedCountry.isocode2,
        recipeConfig: this.formData
      }
      let url =  '/public-api/myrecipe/create-recipe/json'
      axios
        .post(process.env.VUE_APP_RSF_API + url, payload)
        .then((response) => {
          if(payload.recipeConfig.refugium == '1'){
            that.recipeData = response.data;
          }else{
            that.recipeDataWithoutRefugium = response.data;
          }
      })
    },
    fetchTankTypes(){
      let that = this
      let payload = {
        lang: this.siteLanguage,
        isocode: this.selectedCountry.isocode2
      }
      let url = '/public-api/myrecipe/get-tanks/json'
      axios
        .post(process.env.VUE_APP_RSF_API + url, payload)
        .then((response) => {
          that.tankTypes.cards = response.data.data
          for(let i = 0; i < that.tankTypes.cards.length; i++){
            that.tankTypes.cards[i].formDataAttribute = 'tankType'
            if(that.tankTypes.cards[i].autoSelectGoal == "0"){
              that.tankTypes.cards[i].nextStep = 2
            }else{
              that.tankTypes.cards[i].nextStep = 3
            }
          }
          that.itemsLoaded++
        })
        .catch((e) => {
          console.log(e)
        })
    },
    fetchReefingGoals(){
      let that = this
      let payload = {
        lang: this.siteLanguage,
        isocode: this.selectedCountry.isocode2
      }
      let url = '/public-api/myrecipe/get-goals/7/json'
      axios
        .post(process.env.VUE_APP_RSF_API + url, payload)
        .then((response) => {
          that.reefingGoals.cards = response.data.data
          for(let i = 0; i < that.reefingGoals.cards.length; i++){
            that.reefingGoals.cards[i].formDataAttribute = 'reefingGoal'
            that.reefingGoals.cards[i].nextStep = 3
          }

          that.itemsLoaded++
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
}
</script>

<style>
@import './css/myrecipe-styles.css';

#app {
  font-family: 'Open Sans', sans-serif;
}

.myrecipe-wizard{
  /* min-height: 100vh; */
  display: grid; 
  place-items: center;
  padding: 3rem 0;
  opacity: 1;
}

@media(max-width: 1024px){
  .myrecipe-wizard{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.rsf-btn.inactive{
  opacity: 0.5; 
  pointer-events: none;
}

.myrecipe-fade-out{
  transition: .5s ease; 
  opacity: 0
}

/* New card hover and scale animation */
.myrecipe-card-interaction-animation{
  transition: .3s ease; 
}
.myrecipe-card-interaction-animation:hover{
  box-shadow: 0 8px 16px 0 rgba(0, 22, 63, 0.1);
}
.myrecipe-card-interaction-animation.clicked{
  transform: scale(0.95);
}

html[lang='ja'] .helvetica-bold{
  font-family: 'HelveticaNeueLTProRoman' !important;
}
html[lang='ja'] .open-sans-bold{
  font-family: 'Open Sans Regular' !important;
}



</style>
